@tailwind base;
@tailwind components;
@tailwind utilities;

/* Home */

.bannerContainer {
  background-image: linear-gradient(
    to top right,
    #F1E2D2,
    #F1E2D4,
    #F1E2D8,
    #F1E2D1,
    #F1E2DA
  );
}

/* Wave Animation */

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-image: url(./assets/wave.png);
  background-size: 1000px 100px;
  z-index: 10;
}

.wave1 {
  animation: animate 30s linear infinite;
  z-index: 10;
  opacity: 1;
  animation-delay: 0s;
}

.wave2 {
  animation: animate2 15s linear infinite;
  z-index: 9;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 10px;
}
.wave3 {
  animation: animate 10s linear infinite;
  z-index: 8;
  opacity: 0.2;
  animation-delay: -2s;
  bottom: 15px;
}

@keyframes animate {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}
@keyframes animate2 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -1000px;
  }
}

/* project box */
.project-box:hover .project-text {
  left: 0;
  transform: translateX(0);
}

/* nav */
.icon-text {
  display: none;
}
.icon-box:hover .icon-text {
  display: block;
}
.icon-box:hover .icon {
  border: 0;
}

/* about section */
.w-mainCircle {
  position: relative;
  width: 18rem;
  height: 18rem;
  border-radius: 100%;
  top: 2rem;
  background: white;
  box-shadow: 5px 5px 20px rgb(0 0 0 / 20%);
  transition: transform 1.5s ease-in-out;
}
.w-mainCircle:hover {
  /* transform: rotate(360deg); */
  transform: scale(1.1);
}
.w-mainCircle > * {
  position: absolute;
}

.w-mainCircle > :nth-child(1) {
  top: -3rem;
  left: 6rem;
}
.w-mainCircle > :nth-child(2) {
  left: -3rem;
  top: 5rem;
}
.w-mainCircle > :nth-child(3) {
  top: 5rem;
  left: 6rem;
}
.w-mainCircle > :nth-child(4) {
  top: 5rem;
  left: 15rem;
}
.w-mainCircle > :nth-child(5) {
  bottom: -3rem;
  left: 6rem;
}
.w-secCircle img {
  width: 3.5rem;
}
.w-secCircle {
  width: 6.5rem;
  height: 6.5rem;
  overflow: hidden;
  position: absolute;
  border-radius: 100%;
  border: 5px solid #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* hashlink */
.main-nav .active {
  background-color: #E8AF94;
  color: white;
}
.main-nav svg {
  border-color: #2c2255;
}
.main-nav .active svg {
  border-color: #fff;
}
/* resume-btn */
.resume-box:hover .resume-btn {
  display: block;
}

/* title  */
.title-text::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10rem;
  height: 3px;
  background-color: #D5B2B2;
}
.title-text::after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 13rem;
  height: 3px;
  background-color: #BCA2AA;
}

/* scroll bar color change  */

.scroll-box::-webkit-scrollbar {
  width: 10px;
}
.scroll-box::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 11px;
}
.scroll-box::-webkit-scrollbar-thumb {
  background: #f7941e;
  height: 5rem;
  border-radius: 10px;
}

/* arrow */
.arrow-box:hover svg {
  color: #fff;
}
