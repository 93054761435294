.snow-1 {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #C6858D;
    border-radius: 50%;
}

.snow-1:nth-child(1) {
    opacity: 0.6846;
    -webkit-transform: translate(20.7206vw, -10px) scale(0.1595);
    transform: translate(20.7206vw, -10px) scale(0.1595);
    -webkit-animation: fall-1 125s -23s linear infinite;
    animation: fall-1 125s -23s linear infinite;
  }
  @keyframes fall-1 {
    44.001% {
      -webkit-transform: translate(26.8114vw, 528.012px) scale(0.1595);
      transform: translate(26.8114vw, 528.012px) scale(0.1595);
    }
    to {
      -webkit-transform: translate(23.766vw, 100vh) scale(0.1595);
      transform: translate(23.766vw, 100vh) scale(0.1595);
    }
  }
  .snow-1:nth-child(2) {
    opacity: 0.0292;
    -webkit-transform: translate(34.2745vw, -10px) scale(0.551);
    transform: translate(34.2745vw, -10px) scale(0.551);
    -webkit-animation: fall-2 80s -18s linear infinite;
    animation: fall-2 80s -18s linear infinite;
  }
  @keyframes fall-2 {
    77.563% {
      -webkit-transform: translate(36.3139vw, 930.756px) scale(0.551);
      transform: translate(36.3139vw, 930.756px) scale(0.551);
    }
    to {
      -webkit-transform: translate(35.2942vw, 100vh) scale(0.551);
      transform: translate(35.2942vw, 100vh) scale(0.551);
    }
  }
  .snow-1:nth-child(3) {
    opacity: 0.0732;
    -webkit-transform: translate(86.9349vw, -10px) scale(0.5414);
    transform: translate(86.9349vw, -10px) scale(0.5414);
    -webkit-animation: fall-3 130s -20s linear infinite;
    animation: fall-3 130s -20s linear infinite;
  }
  @keyframes fall-3 {
    52.445% {
      -webkit-transform: translate(86.5133vw, 629.34px) scale(0.5414);
      transform: translate(86.5133vw, 629.34px) scale(0.5414);
    }
    to {
      -webkit-transform: translate(86.7241vw, 100vh) scale(0.5414);
      transform: translate(86.7241vw, 100vh) scale(0.5414);
    }
  }
  .snow-1:nth-child(4) {
    opacity: 0.8538;
    -webkit-transform: translate(28.0327vw, -10px) scale(0.1002);
    transform: translate(28.0327vw, -10px) scale(0.1002);
    -webkit-animation: fall-4 130s -12s linear infinite;
    animation: fall-4 130s -12s linear infinite;
  }
  @keyframes fall-4 {
    54.057% {
      -webkit-transform: translate(22.2561vw, 648.684px) scale(0.1002);
      transform: translate(22.2561vw, 648.684px) scale(0.1002);
    }
    to {
      -webkit-transform: translate(25.1444vw, 100vh) scale(0.1002);
      transform: translate(25.1444vw, 100vh) scale(0.1002);
    }
  }
  .snow-1:nth-child(5) {
    opacity: 0.686;
    -webkit-transform: translate(44.3094vw, -10px) scale(0.6669);
    transform: translate(44.3094vw, -10px) scale(0.6669);
    -webkit-animation: fall-5 120s -15s linear infinite;
    animation: fall-5 120s -15s linear infinite;
  }
  @keyframes fall-5 {
    36.015% {
      -webkit-transform: translate(40.6741vw, 432.18px) scale(0.6669);
      transform: translate(40.6741vw, 432.18px) scale(0.6669);
    }
    to {
      -webkit-transform: translate(42.49175vw, 100vh) scale(0.6669);
      transform: translate(42.49175vw, 100vh) scale(0.6669);
    }
  }
  .snow-1:nth-child(6) {
    opacity: 0.0443;
    -webkit-transform: translate(44.6027vw, -10px) scale(0.2712);
    transform: translate(44.6027vw, -10px) scale(0.2712);
    -webkit-animation: fall-6 85s -29s linear infinite;
    animation: fall-6 85s -29s linear infinite;
  }
  @keyframes fall-6 {
    40.287% {
      -webkit-transform: translate(38.3276vw, 483.444px) scale(0.2712);
      transform: translate(38.3276vw, 483.444px) scale(0.2712);
    }
    to {
      -webkit-transform: translate(41.46515vw, 100vh) scale(0.2712);
      transform: translate(41.46515vw, 100vh) scale(0.2712);
    }
  }
  .snow-1:nth-child(7) {
    opacity: 0.0261;
    -webkit-transform: translate(1.1476vw, -10px) scale(0.3105);
    transform: translate(1.1476vw, -10px) scale(0.3105);
    -webkit-animation: fall-7 80s -13s linear infinite;
    animation: fall-7 80s -13s linear infinite;
  }
  @keyframes fall-7 {
    77.887% {
      -webkit-transform: translate(-8.4287vw, 934.644px) scale(0.3105);
      transform: translate(-8.4287vw, 934.644px) scale(0.3105);
    }
    to {
      -webkit-transform: translate(-3.64055vw, 100vh) scale(0.3105);
      transform: translate(-3.64055vw, 100vh) scale(0.3105);
    }
  }
  .snow-1:nth-child(8) {
    opacity: 0.3107;
    -webkit-transform: translate(10.1209vw, -10px) scale(0.8853);
    transform: translate(10.1209vw, -10px) scale(0.8853);
    -webkit-animation: fall-8 105s -9s linear infinite;
    animation: fall-8 105s -9s linear infinite;
  }
  @keyframes fall-8 {
    53.113% {
      -webkit-transform: translate(20.0649vw, 637.356px) scale(0.8853);
      transform: translate(20.0649vw, 637.356px) scale(0.8853);
    }
    to {
      -webkit-transform: translate(15.0929vw, 100vh) scale(0.8853);
      transform: translate(15.0929vw, 100vh) scale(0.8853);
    }
  }
  .snow-1:nth-child(9) {
    opacity: 0.7808;
    -webkit-transform: translate(78.049vw, -10px) scale(0.4427);
    transform: translate(78.049vw, -10px) scale(0.4427);
    -webkit-animation: fall-9 120s -27s linear infinite;
    animation: fall-9 120s -27s linear infinite;
  }
  @keyframes fall-9 {
    46.836% {
      -webkit-transform: translate(71.6887vw, 562.032px) scale(0.4427);
      transform: translate(71.6887vw, 562.032px) scale(0.4427);
    }
    to {
      -webkit-transform: translate(74.86885vw, 100vh) scale(0.4427);
      transform: translate(74.86885vw, 100vh) scale(0.4427);
    }
  }
  .snow-1:nth-child(10) {
    opacity: 0.4904;
    -webkit-transform: translate(28.5362vw, -10px) scale(0.9356);
    transform: translate(28.5362vw, -10px) scale(0.9356);
    -webkit-animation: fall-10 100s -4s linear infinite;
    animation: fall-10 100s -4s linear infinite;
  }
  @keyframes fall-10 {
    45.6% {
      -webkit-transform: translate(23.1849vw, 547.2px) scale(0.9356);
      transform: translate(23.1849vw, 547.2px) scale(0.9356);
    }
    to {
      -webkit-transform: translate(25.86055vw, 100vh) scale(0.9356);
      transform: translate(25.86055vw, 100vh) scale(0.9356);
    }
  }
  .snow-1:nth-child(11) {
    opacity: 0.5273;
    -webkit-transform: translate(70.7768vw, -10px) scale(0.7533);
    transform: translate(70.7768vw, -10px) scale(0.7533);
    -webkit-animation: fall-11 130s -30s linear infinite;
    animation: fall-11 130s -30s linear infinite;
  }
  @keyframes fall-11 {
    43.784% {
      -webkit-transform: translate(69.5474vw, 525.408px) scale(0.7533);
      transform: translate(69.5474vw, 525.408px) scale(0.7533);
    }
    to {
      -webkit-transform: translate(70.1621vw, 100vh) scale(0.7533);
      transform: translate(70.1621vw, 100vh) scale(0.7533);
    }
  }
  .snow-1:nth-child(12) {
    opacity: 0.9491;
    -webkit-transform: translate(61.4097vw, -10px) scale(0.159);
    transform: translate(61.4097vw, -10px) scale(0.159);
    -webkit-animation: fall-12 115s -28s linear infinite;
    animation: fall-12 115s -28s linear infinite;
  }
  @keyframes fall-12 {
    50.273% {
      -webkit-transform: translate(52.3451vw, 603.276px) scale(0.159);
      transform: translate(52.3451vw, 603.276px) scale(0.159);
    }
    to {
      -webkit-transform: translate(56.8774vw, 100vh) scale(0.159);
      transform: translate(56.8774vw, 100vh) scale(0.159);
    }
  }
  .snow-1:nth-child(13) {
    opacity: 0.1907;
    -webkit-transform: translate(48.4441vw, -10px) scale(0.7176);
    transform: translate(48.4441vw, -10px) scale(0.7176);
    -webkit-animation: fall-13 130s -19s linear infinite;
    animation: fall-13 130s -19s linear infinite;
  }
  @keyframes fall-13 {
    62.491% {
      -webkit-transform: translate(48.1598vw, 749.892px) scale(0.7176);
      transform: translate(48.1598vw, 749.892px) scale(0.7176);
    }
    to {
      -webkit-transform: translate(48.30195vw, 100vh) scale(0.7176);
      transform: translate(48.30195vw, 100vh) scale(0.7176);
    }
  }
  .snow-1:nth-child(14) {
    opacity: 0.3302;
    -webkit-transform: translate(47.1675vw, -10px) scale(0.0556);
    transform: translate(47.1675vw, -10px) scale(0.0556);
    -webkit-animation: fall-14 55s -1s linear infinite;
    animation: fall-14 55s -1s linear infinite;
  }
  @keyframes fall-14 {
    35.93% {
      -webkit-transform: translate(47.118vw, 431.16px) scale(0.0556);
      transform: translate(47.118vw, 431.16px) scale(0.0556);
    }
    to {
      -webkit-transform: translate(47.14275vw, 100vh) scale(0.0556);
      transform: translate(47.14275vw, 100vh) scale(0.0556);
    }
  }
  .snow-1:nth-child(15) {
    opacity: 0.6538;
    -webkit-transform: translate(26.9756vw, -10px) scale(0.0582);
    transform: translate(26.9756vw, -10px) scale(0.0582);
    -webkit-animation: fall-15 125s -9s linear infinite;
    animation: fall-15 125s -9s linear infinite;
  }
  @keyframes fall-15 {
    57.524% {
      -webkit-transform: translate(21.5602vw, 690.288px) scale(0.0582);
      transform: translate(21.5602vw, 690.288px) scale(0.0582);
    }
    to {
      -webkit-transform: translate(24.2679vw, 100vh) scale(0.0582);
      transform: translate(24.2679vw, 100vh) scale(0.0582);
    }
  }
  .snow-1:nth-child(16) {
    opacity: 0.059;
    -webkit-transform: translate(17.8638vw, -10px) scale(0.3441);
    transform: translate(17.8638vw, -10px) scale(0.3441);
    -webkit-animation: fall-16 125s -21s linear infinite;
    animation: fall-16 125s -21s linear infinite;
  }
  @keyframes fall-16 {
    33.586% {
      -webkit-transform: translate(14.5963vw, 403.032px) scale(0.3441);
      transform: translate(14.5963vw, 403.032px) scale(0.3441);
    }
    to {
      -webkit-transform: translate(16.23005vw, 100vh) scale(0.3441);
      transform: translate(16.23005vw, 100vh) scale(0.3441);
    }
  }
  .snow-1:nth-child(17) {
    opacity: 0.1423;
    -webkit-transform: translate(28.7176vw, -10px) scale(0.7361);
    transform: translate(28.7176vw, -10px) scale(0.7361);
    -webkit-animation: fall-17 60s -17s linear infinite;
    animation: fall-17 60s -17s linear infinite;
  }
  @keyframes fall-17 {
    50.698% {
      -webkit-transform: translate(19.484vw, 608.376px) scale(0.7361);
      transform: translate(19.484vw, 608.376px) scale(0.7361);
    }
    to {
      -webkit-transform: translate(24.1008vw, 100vh) scale(0.7361);
      transform: translate(24.1008vw, 100vh) scale(0.7361);
    }
  }
  .snow-1:nth-child(18) {
    opacity: 0.4197;
    -webkit-transform: translate(85.5204vw, -10px) scale(0.3395);
    transform: translate(85.5204vw, -10px) scale(0.3395);
    -webkit-animation: fall-18 95s -24s linear infinite;
    animation: fall-18 95s -24s linear infinite;
  }
  @keyframes fall-18 {
    78.004% {
      -webkit-transform: translate(90.3986vw, 936.048px) scale(0.3395);
      transform: translate(90.3986vw, 936.048px) scale(0.3395);
    }
    to {
      -webkit-transform: translate(87.9595vw, 100vh) scale(0.3395);
      transform: translate(87.9595vw, 100vh) scale(0.3395);
    }
  }
  .snow-1:nth-child(19) {
    opacity: 0.7455;
    -webkit-transform: translate(9.0083vw, -10px) scale(0.0494);
    transform: translate(9.0083vw, -10px) scale(0.0494);
    -webkit-animation: fall-19 100s -19s linear infinite;
    animation: fall-19 100s -19s linear infinite;
  }
  @keyframes fall-19 {
    38.214% {
      -webkit-transform: translate(16.3136vw, 458.568px) scale(0.0494);
      transform: translate(16.3136vw, 458.568px) scale(0.0494);
    }
    to {
      -webkit-transform: translate(12.66095vw, 100vh) scale(0.0494);
      transform: translate(12.66095vw, 100vh) scale(0.0494);
    }
  }
  .snow-1:nth-child(20) {
    opacity: 0.5275;
    -webkit-transform: translate(33.0591vw, -10px) scale(0.0265);
    transform: translate(33.0591vw, -10px) scale(0.0265);
    -webkit-animation: fall-20 135s -21s linear infinite;
    animation: fall-20 135s -21s linear infinite;
  }
  @keyframes fall-20 {
    56.767% {
      -webkit-transform: translate(39.7831vw, 681.204px) scale(0.0265);
      transform: translate(39.7831vw, 681.204px) scale(0.0265);
    }
    to {
      -webkit-transform: translate(36.4211vw, 100vh) scale(0.0265);
      transform: translate(36.4211vw, 100vh) scale(0.0265);
    }
  }
  .snow-1:nth-child(21) {
    opacity: 0.7974;
    -webkit-transform: translate(1.1109vw, -10px) scale(0.9142);
    transform: translate(1.1109vw, -10px) scale(0.9142);
    -webkit-animation: fall-21 85s -19s linear infinite;
    animation: fall-21 85s -19s linear infinite;
  }
  @keyframes fall-21 {
    33.987% {
      -webkit-transform: translate(-5.2053vw, 407.844px) scale(0.9142);
      transform: translate(-5.2053vw, 407.844px) scale(0.9142);
    }
    to {
      -webkit-transform: translate(-2.0472vw, 100vh) scale(0.9142);
      transform: translate(-2.0472vw, 100vh) scale(0.9142);
    }
  }
  .snow-1:nth-child(22) {
    opacity: 0.8574;
    -webkit-transform: translate(8.6437vw, -10px) scale(0.5975);
    transform: translate(8.6437vw, -10px) scale(0.5975);
    -webkit-animation: fall-22 125s -22s linear infinite;
    animation: fall-22 125s -22s linear infinite;
  }
  @keyframes fall-22 {
    77.344% {
      -webkit-transform: translate(5.9994vw, 928.128px) scale(0.5975);
      transform: translate(5.9994vw, 928.128px) scale(0.5975);
    }
    to {
      -webkit-transform: translate(7.32155vw, 100vh) scale(0.5975);
      transform: translate(7.32155vw, 100vh) scale(0.5975);
    }
  }
  .snow-1:nth-child(23) {
    opacity: 0.2955;
    -webkit-transform: translate(69.6756vw, -10px) scale(0.5573);
    transform: translate(69.6756vw, -10px) scale(0.5573);
    -webkit-animation: fall-23 135s -25s linear infinite;
    animation: fall-23 135s -25s linear infinite;
  }
  @keyframes fall-23 {
    33.817% {
      -webkit-transform: translate(69.57vw, 405.804px) scale(0.5573);
      transform: translate(69.57vw, 405.804px) scale(0.5573);
    }
    to {
      -webkit-transform: translate(69.6228vw, 100vh) scale(0.5573);
      transform: translate(69.6228vw, 100vh) scale(0.5573);
    }
  }
  .snow-1:nth-child(24) {
    opacity: 0.0019;
    -webkit-transform: translate(33.1341vw, -10px) scale(0.994);
    transform: translate(33.1341vw, -10px) scale(0.994);
    -webkit-animation: fall-24 115s -21s linear infinite;
    animation: fall-24 115s -21s linear infinite;
  }
  @keyframes fall-24 {
    61.963% {
      -webkit-transform: translate(38.1665vw, 743.556px) scale(0.994);
      transform: translate(38.1665vw, 743.556px) scale(0.994);
    }
    to {
      -webkit-transform: translate(35.6503vw, 100vh) scale(0.994);
      transform: translate(35.6503vw, 100vh) scale(0.994);
    }
  }
  .snow-1:nth-child(25) {
    opacity: 0.7884;
    -webkit-transform: translate(69.6363vw, -10px) scale(0.9122);
    transform: translate(69.6363vw, -10px) scale(0.9122);
    -webkit-animation: fall-25 50s -5s linear infinite;
    animation: fall-25 50s -5s linear infinite;
  }
  @keyframes fall-25 {
    72.341% {
      -webkit-transform: translate(60.1821vw, 868.092px) scale(0.9122);
      transform: translate(60.1821vw, 868.092px) scale(0.9122);
    }
    to {
      -webkit-transform: translate(64.9092vw, 100vh) scale(0.9122);
      transform: translate(64.9092vw, 100vh) scale(0.9122);
    }
  }
  .snow-1:nth-child(26) {
    opacity: 0.254;
    -webkit-transform: translate(58.5852vw, -10px) scale(0.033);
    transform: translate(58.5852vw, -10px) scale(0.033);
    -webkit-animation: fall-26 140s -8s linear infinite;
    animation: fall-26 140s -8s linear infinite;
  }
  @keyframes fall-26 {
    40.848% {
      -webkit-transform: translate(59.1807vw, 490.176px) scale(0.033);
      transform: translate(59.1807vw, 490.176px) scale(0.033);
    }
    to {
      -webkit-transform: translate(58.88295vw, 100vh) scale(0.033);
      transform: translate(58.88295vw, 100vh) scale(0.033);
    }
  }
  .snow-1:nth-child(27) {
    opacity: 0.7925;
    -webkit-transform: translate(35.4225vw, -10px) scale(0.2851);
    transform: translate(35.4225vw, -10px) scale(0.2851);
    -webkit-animation: fall-27 110s -15s linear infinite;
    animation: fall-27 110s -15s linear infinite;
  }
  @keyframes fall-27 {
    31.778% {
      -webkit-transform: translate(30.0219vw, 381.336px) scale(0.2851);
      transform: translate(30.0219vw, 381.336px) scale(0.2851);
    }
    to {
      -webkit-transform: translate(32.7222vw, 100vh) scale(0.2851);
      transform: translate(32.7222vw, 100vh) scale(0.2851);
    }
  }
  .snow-1:nth-child(28) {
    opacity: 0.3285;
    -webkit-transform: translate(44.8928vw, -10px) scale(0.0072);
    transform: translate(44.8928vw, -10px) scale(0.0072);
    -webkit-animation: fall-28 145s -13s linear infinite;
    animation: fall-28 145s -13s linear infinite;
  }
  @keyframes fall-28 {
    60.984% {
      -webkit-transform: translate(47.9945vw, 731.808px) scale(0.0072);
      transform: translate(47.9945vw, 731.808px) scale(0.0072);
    }
    to {
      -webkit-transform: translate(46.44365vw, 100vh) scale(0.0072);
      transform: translate(46.44365vw, 100vh) scale(0.0072);
    }
  }
  .snow-1:nth-child(29) {
    opacity: 0.9948;
    -webkit-transform: translate(7.5442vw, -10px) scale(0.9866);
    transform: translate(7.5442vw, -10px) scale(0.9866);
    -webkit-animation: fall-29 130s -1s linear infinite;
    animation: fall-29 130s -1s linear infinite;
  }
  @keyframes fall-29 {
    31.497% {
      -webkit-transform: translate(9.063vw, 377.964px) scale(0.9866);
      transform: translate(9.063vw, 377.964px) scale(0.9866);
    }
    to {
      -webkit-transform: translate(8.3036vw, 100vh) scale(0.9866);
      transform: translate(8.3036vw, 100vh) scale(0.9866);
    }
  }
  .snow-1:nth-child(30) {
    opacity: 0.3773;
    -webkit-transform: translate(24.6633vw, -10px) scale(0.077);
    transform: translate(24.6633vw, -10px) scale(0.077);
    -webkit-animation: fall-30 125s -21s linear infinite;
    animation: fall-30 125s -21s linear infinite;
  }
  @keyframes fall-30 {
    73.613% {
      -webkit-transform: translate(15.8749vw, 883.356px) scale(0.077);
      transform: translate(15.8749vw, 883.356px) scale(0.077);
    }
    to {
      -webkit-transform: translate(20.2691vw, 100vh) scale(0.077);
      transform: translate(20.2691vw, 100vh) scale(0.077);
    }
  }
  .snow-1:nth-child(31) {
    opacity: 0.6109;
    -webkit-transform: translate(74.8423vw, -10px) scale(0.9929);
    transform: translate(74.8423vw, -10px) scale(0.9929);
    -webkit-animation: fall-31 85s -29s linear infinite;
    animation: fall-31 85s -29s linear infinite;
  }
  @keyframes fall-31 {
    32.527% {
      -webkit-transform: translate(76.0303vw, 390.324px) scale(0.9929);
      transform: translate(76.0303vw, 390.324px) scale(0.9929);
    }
    to {
      -webkit-transform: translate(75.4363vw, 100vh) scale(0.9929);
      transform: translate(75.4363vw, 100vh) scale(0.9929);
    }
  }
  .snow-1:nth-child(32) {
    opacity: 0.5458;
    -webkit-transform: translate(66.3861vw, -10px) scale(0.0394);
    transform: translate(66.3861vw, -10px) scale(0.0394);
    -webkit-animation: fall-32 125s -13s linear infinite;
    animation: fall-32 125s -13s linear infinite;
  }
  @keyframes fall-32 {
    45.24% {
      -webkit-transform: translate(70.4438vw, 542.88px) scale(0.0394);
      transform: translate(70.4438vw, 542.88px) scale(0.0394);
    }
    to {
      -webkit-transform: translate(68.41495vw, 100vh) scale(0.0394);
      transform: translate(68.41495vw, 100vh) scale(0.0394);
    }
  }
  .snow-1:nth-child(33) {
    opacity: 0.2025;
    -webkit-transform: translate(11.4905vw, -10px) scale(0.4551);
    transform: translate(11.4905vw, -10px) scale(0.4551);
    -webkit-animation: fall-33 125s -22s linear infinite;
    animation: fall-33 125s -22s linear infinite;
  }
  @keyframes fall-33 {
    53.195% {
      -webkit-transform: translate(12.7859vw, 638.34px) scale(0.4551);
      transform: translate(12.7859vw, 638.34px) scale(0.4551);
    }
    to {
      -webkit-transform: translate(12.1382vw, 100vh) scale(0.4551);
      transform: translate(12.1382vw, 100vh) scale(0.4551);
    }
  }
  .snow-1:nth-child(34) {
    opacity: 0.0319;
    -webkit-transform: translate(50.6444vw, -10px) scale(0.6533);
    transform: translate(50.6444vw, -10px) scale(0.6533);
    -webkit-animation: fall-34 65s -17s linear infinite;
    animation: fall-34 65s -17s linear infinite;
  }
  @keyframes fall-34 {
    62.067% {
      -webkit-transform: translate(41.4664vw, 744.804px) scale(0.6533);
      transform: translate(41.4664vw, 744.804px) scale(0.6533);
    }
    to {
      -webkit-transform: translate(46.0554vw, 100vh) scale(0.6533);
      transform: translate(46.0554vw, 100vh) scale(0.6533);
    }
  }
  .snow-1:nth-child(35) {
    opacity: 0.7426;
    -webkit-transform: translate(2.9754vw, -10px) scale(0.3873);
    transform: translate(2.9754vw, -10px) scale(0.3873);
    -webkit-animation: fall-35 110s -2s linear infinite;
    animation: fall-35 110s -2s linear infinite;
  }
  @keyframes fall-35 {
    31.629% {
      -webkit-transform: translate(10.7961vw, 379.548px) scale(0.3873);
      transform: translate(10.7961vw, 379.548px) scale(0.3873);
    }
    to {
      -webkit-transform: translate(6.88575vw, 100vh) scale(0.3873);
      transform: translate(6.88575vw, 100vh) scale(0.3873);
    }
  }
  .snow-1:nth-child(36) {
    opacity: 0.9352;
    -webkit-transform: translate(55.2894vw, -10px) scale(0.3564);
    transform: translate(55.2894vw, -10px) scale(0.3564);
    -webkit-animation: fall-36 50s -2s linear infinite;
    animation: fall-36 50s -2s linear infinite;
  }
  @keyframes fall-36 {
    60.418% {
      -webkit-transform: translate(60.9469vw, 725.016px) scale(0.3564);
      transform: translate(60.9469vw, 725.016px) scale(0.3564);
    }
    to {
      -webkit-transform: translate(58.11815vw, 100vh) scale(0.3564);
      transform: translate(58.11815vw, 100vh) scale(0.3564);
    }
  }
  .snow-1:nth-child(37) {
    opacity: 0.5379;
    -webkit-transform: translate(6.7453vw, -10px) scale(0.8328);
    transform: translate(6.7453vw, -10px) scale(0.8328);
    -webkit-animation: fall-37 130s -7s linear infinite;
    animation: fall-37 130s -7s linear infinite;
  }
  @keyframes fall-37 {
    30.683% {
      -webkit-transform: translate(9.6703vw, 368.196px) scale(0.8328);
      transform: translate(9.6703vw, 368.196px) scale(0.8328);
    }
    to {
      -webkit-transform: translate(8.2078vw, 100vh) scale(0.8328);
      transform: translate(8.2078vw, 100vh) scale(0.8328);
    }
  }
  .snow-1:nth-child(38) {
    opacity: 0.9426;
    -webkit-transform: translate(34.1518vw, -10px) scale(0.4946);
    transform: translate(34.1518vw, -10px) scale(0.4946);
    -webkit-animation: fall-38 120s -4s linear infinite;
    animation: fall-38 120s -4s linear infinite;
  }
  @keyframes fall-38 {
    79.714% {
      -webkit-transform: translate(42.3204vw, 956.568px) scale(0.4946);
      transform: translate(42.3204vw, 956.568px) scale(0.4946);
    }
    to {
      -webkit-transform: translate(38.2361vw, 100vh) scale(0.4946);
      transform: translate(38.2361vw, 100vh) scale(0.4946);
    }
  }
  .snow-1:nth-child(39) {
    opacity: 0.0768;
    -webkit-transform: translate(8.7959vw, -10px) scale(0.3936);
    transform: translate(8.7959vw, -10px) scale(0.3936);
    -webkit-animation: fall-39 50s -17s linear infinite;
    animation: fall-39 50s -17s linear infinite;
  }
  @keyframes fall-39 {
    62.112% {
      -webkit-transform: translate(3.8698vw, 745.344px) scale(0.3936);
      transform: translate(3.8698vw, 745.344px) scale(0.3936);
    }
    to {
      -webkit-transform: translate(6.33285vw, 100vh) scale(0.3936);
      transform: translate(6.33285vw, 100vh) scale(0.3936);
    }
  }
  .snow-1:nth-child(40) {
    opacity: 0.1127;
    -webkit-transform: translate(89.578vw, -10px) scale(0.4579);
    transform: translate(89.578vw, -10px) scale(0.4579);
    -webkit-animation: fall-40 80s -15s linear infinite;
    animation: fall-40 80s -15s linear infinite;
  }
  @keyframes fall-40 {
    60.158% {
      -webkit-transform: translate(92.399vw, 721.896px) scale(0.4579);
      transform: translate(92.399vw, 721.896px) scale(0.4579);
    }
    to {
      -webkit-transform: translate(90.9885vw, 100vh) scale(0.4579);
      transform: translate(90.9885vw, 100vh) scale(0.4579);
    }
  }
  .snow-1:nth-child(41) {
    opacity: 0.5451;
    -webkit-transform: translate(24.4426vw, -10px) scale(0.3874);
    transform: translate(24.4426vw, -10px) scale(0.3874);
    -webkit-animation: fall-41 55s -2s linear infinite;
    animation: fall-41 55s -2s linear infinite;
  }
  @keyframes fall-41 {
    46.175% {
      -webkit-transform: translate(27.7987vw, 554.1px) scale(0.3874);
      transform: translate(27.7987vw, 554.1px) scale(0.3874);
    }
    to {
      -webkit-transform: translate(26.12065vw, 100vh) scale(0.3874);
      transform: translate(26.12065vw, 100vh) scale(0.3874);
    }
  }
  .snow-1:nth-child(42) {
    opacity: 0.8576;
    -webkit-transform: translate(60.335vw, -10px) scale(0.5664);
    transform: translate(60.335vw, -10px) scale(0.5664);
    -webkit-animation: fall-42 85s -26s linear infinite;
    animation: fall-42 85s -26s linear infinite;
  }
  @keyframes fall-42 {
    58.751% {
      -webkit-transform: translate(67.7221vw, 705.012px) scale(0.5664);
      transform: translate(67.7221vw, 705.012px) scale(0.5664);
    }
    to {
      -webkit-transform: translate(64.02855vw, 100vh) scale(0.5664);
      transform: translate(64.02855vw, 100vh) scale(0.5664);
    }
  }
  .snow-1:nth-child(43) {
    opacity: 0.9965;
    -webkit-transform: translate(45.9996vw, -10px) scale(0.751);
    transform: translate(45.9996vw, -10px) scale(0.751);
    -webkit-animation: fall-43 90s -2s linear infinite;
    animation: fall-43 90s -2s linear infinite;
  }
  @keyframes fall-43 {
    45.247% {
      -webkit-transform: translate(43.5969vw, 542.964px) scale(0.751);
      transform: translate(43.5969vw, 542.964px) scale(0.751);
    }
    to {
      -webkit-transform: translate(44.79825vw, 100vh) scale(0.751);
      transform: translate(44.79825vw, 100vh) scale(0.751);
    }
  }
  .snow-1:nth-child(44) {
    opacity: 0.9389;
    -webkit-transform: translate(77.8516vw, -10px) scale(0.036);
    transform: translate(77.8516vw, -10px) scale(0.036);
    -webkit-animation: fall-44 90s -14s linear infinite;
    animation: fall-44 90s -14s linear infinite;
  }
  @keyframes fall-44 {
    65.566% {
      -webkit-transform: translate(81.5509vw, 786.792px) scale(0.036);
      transform: translate(81.5509vw, 786.792px) scale(0.036);
    }
    to {
      -webkit-transform: translate(79.70125vw, 100vh) scale(0.036);
      transform: translate(79.70125vw, 100vh) scale(0.036);
    }
  }
  .snow-1:nth-child(45) {
    opacity: 0.0747;
    -webkit-transform: translate(42.4355vw, -10px) scale(0.0737);
    transform: translate(42.4355vw, -10px) scale(0.0737);
    -webkit-animation: fall-45 80s -9s linear infinite;
    animation: fall-45 80s -9s linear infinite;
  }
  @keyframes fall-45 {
    68.91% {
      -webkit-transform: translate(36.4115vw, 826.92px) scale(0.0737);
      transform: translate(36.4115vw, 826.92px) scale(0.0737);
    }
    to {
      -webkit-transform: translate(39.4235vw, 100vh) scale(0.0737);
      transform: translate(39.4235vw, 100vh) scale(0.0737);
    }
  }
  .snow-1:nth-child(46) {
    opacity: 0.7227;
    -webkit-transform: translate(84.06vw, -10px) scale(0.0655);
    transform: translate(84.06vw, -10px) scale(0.0655);
    -webkit-animation: fall-46 110s -2s linear infinite;
    animation: fall-46 110s -2s linear infinite;
  }
  @keyframes fall-46 {
    50.919% {
      -webkit-transform: translate(80.6357vw, 611.028px) scale(0.0655);
      transform: translate(80.6357vw, 611.028px) scale(0.0655);
    }
    to {
      -webkit-transform: translate(82.34785vw, 100vh) scale(0.0655);
      transform: translate(82.34785vw, 100vh) scale(0.0655);
    }
  }
  .snow-1:nth-child(47) {
    opacity: 0.2518;
    -webkit-transform: translate(26.9441vw, -10px) scale(0.2108);
    transform: translate(26.9441vw, -10px) scale(0.2108);
    -webkit-animation: fall-47 55s -30s linear infinite;
    animation: fall-47 55s -30s linear infinite;
  }
  @keyframes fall-47 {
    58.183% {
      -webkit-transform: translate(22.4792vw, 698.196px) scale(0.2108);
      transform: translate(22.4792vw, 698.196px) scale(0.2108);
    }
    to {
      -webkit-transform: translate(24.71165vw, 100vh) scale(0.2108);
      transform: translate(24.71165vw, 100vh) scale(0.2108);
    }
  }
  .snow-1:nth-child(48) {
    opacity: 0.5323;
    -webkit-transform: translate(13.1673vw, -10px) scale(0.9936);
    transform: translate(13.1673vw, -10px) scale(0.9936);
    -webkit-animation: fall-48 130s -7s linear infinite;
    animation: fall-48 130s -7s linear infinite;
  }
  @keyframes fall-48 {
    37.723% {
      -webkit-transform: translate(15.4203vw, 452.676px) scale(0.9936);
      transform: translate(15.4203vw, 452.676px) scale(0.9936);
    }
    to {
      -webkit-transform: translate(14.2938vw, 100vh) scale(0.9936);
      transform: translate(14.2938vw, 100vh) scale(0.9936);
    }
  }
  .snow-1:nth-child(49) {
    opacity: 0.0803;
    -webkit-transform: translate(52.4628vw, -10px) scale(0.1848);
    transform: translate(52.4628vw, -10px) scale(0.1848);
    -webkit-animation: fall-49 80s -16s linear infinite;
    animation: fall-49 80s -16s linear infinite;
  }
  @keyframes fall-49 {
    73.252% {
      -webkit-transform: translate(51.4061vw, 879.024px) scale(0.1848);
      transform: translate(51.4061vw, 879.024px) scale(0.1848);
    }
    to {
      -webkit-transform: translate(51.93445vw, 100vh) scale(0.1848);
      transform: translate(51.93445vw, 100vh) scale(0.1848);
    }
  }
  .snow-1:nth-child(50) {
    opacity: 0.8859;
    -webkit-transform: translate(80.229vw, -10px) scale(0.1712);
    transform: translate(80.229vw, -10px) scale(0.1712);
    -webkit-animation: fall-50 95s -16s linear infinite;
    animation: fall-50 95s -16s linear infinite;
  }
  @keyframes fall-50 {
    54.499% {
      -webkit-transform: translate(76.5743vw, 653.988px) scale(0.1712);
      transform: translate(76.5743vw, 653.988px) scale(0.1712);
    }
    to {
      -webkit-transform: translate(78.40165vw, 100vh) scale(0.1712);
      transform: translate(78.40165vw, 100vh) scale(0.1712);
    }
  }
  .snow-1:nth-child(51) {
    opacity: 0.6085;
    -webkit-transform: translate(7.3849vw, -10px) scale(0.0879);
    transform: translate(7.3849vw, -10px) scale(0.0879);
    -webkit-animation: fall-51 80s -26s linear infinite;
    animation: fall-51 80s -26s linear infinite;
  }
  @keyframes fall-51 {
    75.427% {
      -webkit-transform: translate(15.1029vw, 905.124px) scale(0.0879);
      transform: translate(15.1029vw, 905.124px) scale(0.0879);
    }
    to {
      -webkit-transform: translate(11.2439vw, 100vh) scale(0.0879);
      transform: translate(11.2439vw, 100vh) scale(0.0879);
    }
  }
  .snow-1:nth-child(52) {
    opacity: 0.1573;
    -webkit-transform: translate(31.2218vw, -10px) scale(0.8265);
    transform: translate(31.2218vw, -10px) scale(0.8265);
    -webkit-animation: fall-52 135s -13s linear infinite;
    animation: fall-52 135s -13s linear infinite;
  }
  @keyframes fall-52 {
    41.399% {
      -webkit-transform: translate(34.6072vw, 496.788px) scale(0.8265);
      transform: translate(34.6072vw, 496.788px) scale(0.8265);
    }
    to {
      -webkit-transform: translate(32.9145vw, 100vh) scale(0.8265);
      transform: translate(32.9145vw, 100vh) scale(0.8265);
    }
  }
  .snow-1:nth-child(53) {
    opacity: 0.2257;
    -webkit-transform: translate(33.8885vw, -10px) scale(0.3153);
    transform: translate(33.8885vw, -10px) scale(0.3153);
    -webkit-animation: fall-53 50s -18s linear infinite;
    animation: fall-53 50s -18s linear infinite;
  }
  @keyframes fall-53 {
    74.572% {
      -webkit-transform: translate(38.5373vw, 894.864px) scale(0.3153);
      transform: translate(38.5373vw, 894.864px) scale(0.3153);
    }
    to {
      -webkit-transform: translate(36.2129vw, 100vh) scale(0.3153);
      transform: translate(36.2129vw, 100vh) scale(0.3153);
    }
  }
  .snow-1:nth-child(54) {
    opacity: 0.7835;
    -webkit-transform: translate(53.9387vw, -10px) scale(0.419);
    transform: translate(53.9387vw, -10px) scale(0.419);
    -webkit-animation: fall-54 125s -7s linear infinite;
    animation: fall-54 125s -7s linear infinite;
  }
  @keyframes fall-54 {
    67.811% {
      -webkit-transform: translate(53.1339vw, 813.732px) scale(0.419);
      transform: translate(53.1339vw, 813.732px) scale(0.419);
    }
    to {
      -webkit-transform: translate(53.5363vw, 100vh) scale(0.419);
      transform: translate(53.5363vw, 100vh) scale(0.419);
    }
  }
  .snow-1:nth-child(55) {
    opacity: 0.4834;
    -webkit-transform: translate(1.5113vw, -10px) scale(0.7023);
    transform: translate(1.5113vw, -10px) scale(0.7023);
    -webkit-animation: fall-55 120s -1s linear infinite;
    animation: fall-55 120s -1s linear infinite;
  }
  @keyframes fall-55 {
    77.968% {
      -webkit-transform: translate(-3.7789vw, 935.616px) scale(0.7023);
      transform: translate(-3.7789vw, 935.616px) scale(0.7023);
    }
    to {
      -webkit-transform: translate(-1.1338vw, 100vh) scale(0.7023);
      transform: translate(-1.1338vw, 100vh) scale(0.7023);
    }
  }
  .snow-1:nth-child(56) {
    opacity: 0.0575;
    -webkit-transform: translate(71.817vw, -10px) scale(0.934);
    transform: translate(71.817vw, -10px) scale(0.934);
    -webkit-animation: fall-56 60s -13s linear infinite;
    animation: fall-56 60s -13s linear infinite;
  }
  @keyframes fall-56 {
    74.762% {
      -webkit-transform: translate(72.9757vw, 897.144px) scale(0.934);
      transform: translate(72.9757vw, 897.144px) scale(0.934);
    }
    to {
      -webkit-transform: translate(72.39635vw, 100vh) scale(0.934);
      transform: translate(72.39635vw, 100vh) scale(0.934);
    }
  }
  .snow-1:nth-child(57) {
    opacity: 0.5872;
    -webkit-transform: translate(47.7675vw, -10px) scale(0.2035);
    transform: translate(47.7675vw, -10px) scale(0.2035);
    -webkit-animation: fall-57 135s -29s linear infinite;
    animation: fall-57 135s -29s linear infinite;
  }
  @keyframes fall-57 {
    54.86% {
      -webkit-transform: translate(46.429vw, 658.32px) scale(0.2035);
      transform: translate(46.429vw, 658.32px) scale(0.2035);
    }
    to {
      -webkit-transform: translate(47.09825vw, 100vh) scale(0.2035);
      transform: translate(47.09825vw, 100vh) scale(0.2035);
    }
  }
  .snow-1:nth-child(58) {
    opacity: 0.4323;
    -webkit-transform: translate(11.7421vw, -10px) scale(0.3227);
    transform: translate(11.7421vw, -10px) scale(0.3227);
    -webkit-animation: fall-58 105s -11s linear infinite;
    animation: fall-58 105s -11s linear infinite;
  }
  @keyframes fall-58 {
    54.59% {
      -webkit-transform: translate(15.7359vw, 655.08px) scale(0.3227);
      transform: translate(15.7359vw, 655.08px) scale(0.3227);
    }
    to {
      -webkit-transform: translate(13.739vw, 100vh) scale(0.3227);
      transform: translate(13.739vw, 100vh) scale(0.3227);
    }
  }
  .snow-1:nth-child(59) {
    opacity: 0.87;
    -webkit-transform: translate(50.9772vw, -10px) scale(0.8533);
    transform: translate(50.9772vw, -10px) scale(0.8533);
    -webkit-animation: fall-59 115s -28s linear infinite;
    animation: fall-59 115s -28s linear infinite;
  }
  @keyframes fall-59 {
    39.612% {
      -webkit-transform: translate(48.1321vw, 475.344px) scale(0.8533);
      transform: translate(48.1321vw, 475.344px) scale(0.8533);
    }
    to {
      -webkit-transform: translate(49.55465vw, 100vh) scale(0.8533);
      transform: translate(49.55465vw, 100vh) scale(0.8533);
    }
  }
  .snow-1:nth-child(60) {
    opacity: 0.0065;
    -webkit-transform: translate(59.5758vw, -10px) scale(0.846);
    transform: translate(59.5758vw, -10px) scale(0.846);
    -webkit-animation: fall-60 115s -17s linear infinite;
    animation: fall-60 115s -17s linear infinite;
  }
  @keyframes fall-60 {
    56.129% {
      -webkit-transform: translate(51.5101vw, 673.548px) scale(0.846);
      transform: translate(51.5101vw, 673.548px) scale(0.846);
    }
    to {
      -webkit-transform: translate(55.54295vw, 100vh) scale(0.846);
      transform: translate(55.54295vw, 100vh) scale(0.846);
    }
  }
  .snow-1:nth-child(61) {
    opacity: 0.1759;
    -webkit-transform: translate(73.5902vw, -10px) scale(0.4484);
    transform: translate(73.5902vw, -10px) scale(0.4484);
    -webkit-animation: fall-61 115s -22s linear infinite;
    animation: fall-61 115s -22s linear infinite;
  }
  @keyframes fall-61 {
    32.442% {
      -webkit-transform: translate(79.3664vw, 389.304px) scale(0.4484);
      transform: translate(79.3664vw, 389.304px) scale(0.4484);
    }
    to {
      -webkit-transform: translate(76.4783vw, 100vh) scale(0.4484);
      transform: translate(76.4783vw, 100vh) scale(0.4484);
    }
  }
  .snow-1:nth-child(62) {
    opacity: 0.5777;
    -webkit-transform: translate(79.2144vw, -10px) scale(0.544);
    transform: translate(79.2144vw, -10px) scale(0.544);
    -webkit-animation: fall-62 80s -24s linear infinite;
    animation: fall-62 80s -24s linear infinite;
  }
  @keyframes fall-62 {
    58.651% {
      -webkit-transform: translate(83.3753vw, 703.812px) scale(0.544);
      transform: translate(83.3753vw, 703.812px) scale(0.544);
    }
    to {
      -webkit-transform: translate(81.29485vw, 100vh) scale(0.544);
      transform: translate(81.29485vw, 100vh) scale(0.544);
    }
  }
  .snow-1:nth-child(63) {
    opacity: 0.4963;
    -webkit-transform: translate(48.7651vw, -10px) scale(0.945);
    transform: translate(48.7651vw, -10px) scale(0.945);
    -webkit-animation: fall-63 140s -19s linear infinite;
    animation: fall-63 140s -19s linear infinite;
  }
  @keyframes fall-63 {
    65.904% {
      -webkit-transform: translate(42.0815vw, 790.848px) scale(0.945);
      transform: translate(42.0815vw, 790.848px) scale(0.945);
    }
    to {
      -webkit-transform: translate(45.4233vw, 100vh) scale(0.945);
      transform: translate(45.4233vw, 100vh) scale(0.945);
    }
  }
  .snow-1:nth-child(64) {
    opacity: 0.4769;
    -webkit-transform: translate(55.0059vw, -10px) scale(0.0596);
    transform: translate(55.0059vw, -10px) scale(0.0596);
    -webkit-animation: fall-64 110s -8s linear infinite;
    animation: fall-64 110s -8s linear infinite;
  }
  @keyframes fall-64 {
    31.932% {
      -webkit-transform: translate(64.7286vw, 383.184px) scale(0.0596);
      transform: translate(64.7286vw, 383.184px) scale(0.0596);
    }
    to {
      -webkit-transform: translate(59.86725vw, 100vh) scale(0.0596);
      transform: translate(59.86725vw, 100vh) scale(0.0596);
    }
  }
  .snow-1:nth-child(65) {
    opacity: 0.9213;
    -webkit-transform: translate(25.5408vw, -10px) scale(0.8841);
    transform: translate(25.5408vw, -10px) scale(0.8841);
    -webkit-animation: fall-65 95s -24s linear infinite;
    animation: fall-65 95s -24s linear infinite;
  }
  @keyframes fall-65 {
    76.889% {
      -webkit-transform: translate(34.8909vw, 922.668px) scale(0.8841);
      transform: translate(34.8909vw, 922.668px) scale(0.8841);
    }
    to {
      -webkit-transform: translate(30.21585vw, 100vh) scale(0.8841);
      transform: translate(30.21585vw, 100vh) scale(0.8841);
    }
  }
  .snow-1:nth-child(66) {
    opacity: 0.1982;
    -webkit-transform: translate(71.5055vw, -10px) scale(0.4848);
    transform: translate(71.5055vw, -10px) scale(0.4848);
    -webkit-animation: fall-66 65s -17s linear infinite;
    animation: fall-66 65s -17s linear infinite;
  }
  @keyframes fall-66 {
    73.455% {
      -webkit-transform: translate(73.7423vw, 881.46px) scale(0.4848);
      transform: translate(73.7423vw, 881.46px) scale(0.4848);
    }
    to {
      -webkit-transform: translate(72.6239vw, 100vh) scale(0.4848);
      transform: translate(72.6239vw, 100vh) scale(0.4848);
    }
  }
  .snow-1:nth-child(67) {
    opacity: 0.5687;
    -webkit-transform: translate(26.8961vw, -10px) scale(0.1316);
    transform: translate(26.8961vw, -10px) scale(0.1316);
    -webkit-animation: fall-67 120s -2s linear infinite;
    animation: fall-67 120s -2s linear infinite;
  }
  @keyframes fall-67 {
    46.637% {
      -webkit-transform: translate(28.1316vw, 559.644px) scale(0.1316);
      transform: translate(28.1316vw, 559.644px) scale(0.1316);
    }
    to {
      -webkit-transform: translate(27.51385vw, 100vh) scale(0.1316);
      transform: translate(27.51385vw, 100vh) scale(0.1316);
    }
  }
  .snow-1:nth-child(68) {
    opacity: 0.5109;
    -webkit-transform: translate(86.7269vw, -10px) scale(0.778);
    transform: translate(86.7269vw, -10px) scale(0.778);
    -webkit-animation: fall-68 60s -15s linear infinite;
    animation: fall-68 60s -15s linear infinite;
  }
  @keyframes fall-68 {
    69.707% {
      -webkit-transform: translate(87.7087vw, 836.484px) scale(0.778);
      transform: translate(87.7087vw, 836.484px) scale(0.778);
    }
    to {
      -webkit-transform: translate(87.2178vw, 100vh) scale(0.778);
      transform: translate(87.2178vw, 100vh) scale(0.778);
    }
  }
  .snow-1:nth-child(69) {
    opacity: 0.1922;
    -webkit-transform: translate(12.231vw, -10px) scale(0.2427);
    transform: translate(12.231vw, -10px) scale(0.2427);
    -webkit-animation: fall-69 85s -21s linear infinite;
    animation: fall-69 85s -21s linear infinite;
  }
  @keyframes fall-69 {
    48.963% {
      -webkit-transform: translate(4.4159vw, 587.556px) scale(0.2427);
      transform: translate(4.4159vw, 587.556px) scale(0.2427);
    }
    to {
      -webkit-transform: translate(8.32345vw, 100vh) scale(0.2427);
      transform: translate(8.32345vw, 100vh) scale(0.2427);
    }
  }
  .snow-1:nth-child(70) {
    opacity: 0.2764;
    -webkit-transform: translate(49.929vw, -10px) scale(0.8842);
    transform: translate(49.929vw, -10px) scale(0.8842);
    -webkit-animation: fall-70 105s -13s linear infinite;
    animation: fall-70 105s -13s linear infinite;
  }
  @keyframes fall-70 {
    55.632% {
      -webkit-transform: translate(42.0349vw, 667.584px) scale(0.8842);
      transform: translate(42.0349vw, 667.584px) scale(0.8842);
    }
    to {
      -webkit-transform: translate(45.98195vw, 100vh) scale(0.8842);
      transform: translate(45.98195vw, 100vh) scale(0.8842);
    }
  }
  .snow-1:nth-child(71) {
    opacity: 0.3685;
    -webkit-transform: translate(68.3292vw, -10px) scale(0.5043);
    transform: translate(68.3292vw, -10px) scale(0.5043);
    -webkit-animation: fall-71 95s -21s linear infinite;
    animation: fall-71 95s -21s linear infinite;
  }
  @keyframes fall-71 {
    54.022% {
      -webkit-transform: translate(60.9352vw, 648.264px) scale(0.5043);
      transform: translate(60.9352vw, 648.264px) scale(0.5043);
    }
    to {
      -webkit-transform: translate(64.6322vw, 100vh) scale(0.5043);
      transform: translate(64.6322vw, 100vh) scale(0.5043);
    }
  }
  .snow-1:nth-child(72) {
    opacity: 0.9006;
    -webkit-transform: translate(25.6698vw, -10px) scale(0.9498);
    transform: translate(25.6698vw, -10px) scale(0.9498);
    -webkit-animation: fall-72 125s -6s linear infinite;
    animation: fall-72 125s -6s linear infinite;
  }
  @keyframes fall-72 {
    61.43% {
      -webkit-transform: translate(25.7149vw, 737.16px) scale(0.9498);
      transform: translate(25.7149vw, 737.16px) scale(0.9498);
    }
    to {
      -webkit-transform: translate(25.69235vw, 100vh) scale(0.9498);
      transform: translate(25.69235vw, 100vh) scale(0.9498);
    }
  }
  .snow-1:nth-child(73) {
    opacity: 0.3607;
    -webkit-transform: translate(12.6061vw, -10px) scale(0.1477);
    transform: translate(12.6061vw, -10px) scale(0.1477);
    -webkit-animation: fall-73 75s -6s linear infinite;
    animation: fall-73 75s -6s linear infinite;
  }
  @keyframes fall-73 {
    69.183% {
      -webkit-transform: translate(16.549vw, 830.196px) scale(0.1477);
      transform: translate(16.549vw, 830.196px) scale(0.1477);
    }
    to {
      -webkit-transform: translate(14.57755vw, 100vh) scale(0.1477);
      transform: translate(14.57755vw, 100vh) scale(0.1477);
    }
  }
  .snow-1:nth-child(74) {
    opacity: 0.9768;
    -webkit-transform: translate(9.7346vw, -10px) scale(0.8002);
    transform: translate(9.7346vw, -10px) scale(0.8002);
    -webkit-animation: fall-74 60s -30s linear infinite;
    animation: fall-74 60s -30s linear infinite;
  }
  @keyframes fall-74 {
    51.905% {
      -webkit-transform: translate(14.7289vw, 622.86px) scale(0.8002);
      transform: translate(14.7289vw, 622.86px) scale(0.8002);
    }
    to {
      -webkit-transform: translate(12.23175vw, 100vh) scale(0.8002);
      transform: translate(12.23175vw, 100vh) scale(0.8002);
    }
  }
  .snow-1:nth-child(75) {
    opacity: 0.0199;
    -webkit-transform: translate(61.7884vw, -10px) scale(0.0077);
    transform: translate(61.7884vw, -10px) scale(0.0077);
    -webkit-animation: fall-75 70s -8s linear infinite;
    animation: fall-75 70s -8s linear infinite;
  }
  @keyframes fall-75 {
    32.029% {
      -webkit-transform: translate(69.235vw, 384.348px) scale(0.0077);
      transform: translate(69.235vw, 384.348px) scale(0.0077);
    }
    to {
      -webkit-transform: translate(65.5117vw, 100vh) scale(0.0077);
      transform: translate(65.5117vw, 100vh) scale(0.0077);
    }
  }
  .snow-1:nth-child(76) {
    opacity: 0.8697;
    -webkit-transform: translate(80.3492vw, -10px) scale(0.8857);
    transform: translate(80.3492vw, -10px) scale(0.8857);
    -webkit-animation: fall-76 150s -18s linear infinite;
    animation: fall-76 150s -18s linear infinite;
  }
  @keyframes fall-76 {
    71.193% {
      -webkit-transform: translate(84.9038vw, 854.316px) scale(0.8857);
      transform: translate(84.9038vw, 854.316px) scale(0.8857);
    }
    to {
      -webkit-transform: translate(82.6265vw, 100vh) scale(0.8857);
      transform: translate(82.6265vw, 100vh) scale(0.8857);
    }
  }
  .snow-1:nth-child(77) {
    opacity: 0.5355;
    -webkit-transform: translate(1.794vw, -10px) scale(0.1379);
    transform: translate(1.794vw, -10px) scale(0.1379);
    -webkit-animation: fall-77 100s -27s linear infinite;
    animation: fall-77 100s -27s linear infinite;
  }
  @keyframes fall-77 {
    36.266% {
      -webkit-transform: translate(-0.5146vw, 435.192px) scale(0.1379);
      transform: translate(-0.5146vw, 435.192px) scale(0.1379);
    }
    to {
      -webkit-transform: translate(0.6397vw, 100vh) scale(0.1379);
      transform: translate(0.6397vw, 100vh) scale(0.1379);
    }
  }
  .snow-1:nth-child(78) {
    opacity: 0.8716;
    -webkit-transform: translate(36.5258vw, -10px) scale(0.0941);
    transform: translate(36.5258vw, -10px) scale(0.0941);
    -webkit-animation: fall-78 125s -2s linear infinite;
    animation: fall-78 125s -2s linear infinite;
  }
  @keyframes fall-78 {
    66.99% {
      -webkit-transform: translate(34.9451vw, 803.88px) scale(0.0941);
      transform: translate(34.9451vw, 803.88px) scale(0.0941);
    }
    to {
      -webkit-transform: translate(35.73545vw, 100vh) scale(0.0941);
      transform: translate(35.73545vw, 100vh) scale(0.0941);
    }
  }
  .snow-1:nth-child(79) {
    opacity: 0.8068;
    -webkit-transform: translate(58.1546vw, -10px) scale(0.5197);
    transform: translate(58.1546vw, -10px) scale(0.5197);
    -webkit-animation: fall-79 50s -23s linear infinite;
    animation: fall-79 50s -23s linear infinite;
  }
  @keyframes fall-79 {
    62.389% {
      -webkit-transform: translate(63.5781vw, 748.668px) scale(0.5197);
      transform: translate(63.5781vw, 748.668px) scale(0.5197);
    }
    to {
      -webkit-transform: translate(60.86635vw, 100vh) scale(0.5197);
      transform: translate(60.86635vw, 100vh) scale(0.5197);
    }
  }
  .snow-1:nth-child(80) {
    opacity: 0.3616;
    -webkit-transform: translate(89.8325vw, -10px) scale(0.1292);
    transform: translate(89.8325vw, -10px) scale(0.1292);
    -webkit-animation: fall-80 85s -5s linear infinite;
    animation: fall-80 85s -5s linear infinite;
  }
  @keyframes fall-80 {
    62.204% {
      -webkit-transform: translate(99.0152vw, 746.448px) scale(0.1292);
      transform: translate(99.0152vw, 746.448px) scale(0.1292);
    }
    to {
      -webkit-transform: translate(94.42385vw, 100vh) scale(0.1292);
      transform: translate(94.42385vw, 100vh) scale(0.1292);
    }
  }
  .snow-1:nth-child(81) {
    opacity: 0.5081;
    -webkit-transform: translate(72.1239vw, -10px) scale(0.573);
    transform: translate(72.1239vw, -10px) scale(0.573);
    -webkit-animation: fall-81 60s -19s linear infinite;
    animation: fall-81 60s -19s linear infinite;
  }
  @keyframes fall-81 {
    33.055% {
      -webkit-transform: translate(76.7959vw, 396.66px) scale(0.573);
      transform: translate(76.7959vw, 396.66px) scale(0.573);
    }
    to {
      -webkit-transform: translate(74.4599vw, 100vh) scale(0.573);
      transform: translate(74.4599vw, 100vh) scale(0.573);
    }
  }
  .snow-1:nth-child(82) {
    opacity: 0.1092;
    -webkit-transform: translate(54.9062vw, -10px) scale(0.8489);
    transform: translate(54.9062vw, -10px) scale(0.8489);
    -webkit-animation: fall-82 130s -8s linear infinite;
    animation: fall-82 130s -8s linear infinite;
  }
  @keyframes fall-82 {
    59.053% {
      -webkit-transform: translate(48.1423vw, 708.636px) scale(0.8489);
      transform: translate(48.1423vw, 708.636px) scale(0.8489);
    }
    to {
      -webkit-transform: translate(51.52425vw, 100vh) scale(0.8489);
      transform: translate(51.52425vw, 100vh) scale(0.8489);
    }
  }
  .snow-1:nth-child(83) {
    opacity: 0.9369;
    -webkit-transform: translate(16.3169vw, -10px) scale(0.4337);
    transform: translate(16.3169vw, -10px) scale(0.4337);
    -webkit-animation: fall-83 150s -22s linear infinite;
    animation: fall-83 150s -22s linear infinite;
  }
  @keyframes fall-83 {
    39.73% {
      -webkit-transform: translate(15.8722vw, 476.76px) scale(0.4337);
      transform: translate(15.8722vw, 476.76px) scale(0.4337);
    }
    to {
      -webkit-transform: translate(16.09455vw, 100vh) scale(0.4337);
      transform: translate(16.09455vw, 100vh) scale(0.4337);
    }
  }
  .snow-1:nth-child(84) {
    opacity: 0.4249;
    -webkit-transform: translate(39.0725vw, -10px) scale(0.1696);
    transform: translate(39.0725vw, -10px) scale(0.1696);
    -webkit-animation: fall-84 55s -27s linear infinite;
    animation: fall-84 55s -27s linear infinite;
  }
  @keyframes fall-84 {
    65.674% {
      -webkit-transform: translate(39.6426vw, 788.088px) scale(0.1696);
      transform: translate(39.6426vw, 788.088px) scale(0.1696);
    }
    to {
      -webkit-transform: translate(39.35755vw, 100vh) scale(0.1696);
      transform: translate(39.35755vw, 100vh) scale(0.1696);
    }
  }
  .snow-1:nth-child(85) {
    opacity: 0.337;
    -webkit-transform: translate(20.3749vw, -10px) scale(0.5851);
    transform: translate(20.3749vw, -10px) scale(0.5851);
    -webkit-animation: fall-85 110s -28s linear infinite;
    animation: fall-85 110s -28s linear infinite;
  }
  @keyframes fall-85 {
    75.03% {
      -webkit-transform: translate(15.477vw, 900.36px) scale(0.5851);
      transform: translate(15.477vw, 900.36px) scale(0.5851);
    }
    to {
      -webkit-transform: translate(17.92595vw, 100vh) scale(0.5851);
      transform: translate(17.92595vw, 100vh) scale(0.5851);
    }
  }
  .snow-1:nth-child(86) {
    opacity: 0.3649;
    -webkit-transform: translate(25.3049vw, -10px) scale(0.6922);
    transform: translate(25.3049vw, -10px) scale(0.6922);
    -webkit-animation: fall-86 105s -6s linear infinite;
    animation: fall-86 105s -6s linear infinite;
  }
  @keyframes fall-86 {
    73.828% {
      -webkit-transform: translate(24.5713vw, 885.936px) scale(0.6922);
      transform: translate(24.5713vw, 885.936px) scale(0.6922);
    }
    to {
      -webkit-transform: translate(24.9381vw, 100vh) scale(0.6922);
      transform: translate(24.9381vw, 100vh) scale(0.6922);
    }
  }
  .snow-1:nth-child(87) {
    opacity: 0.1693;
    -webkit-transform: translate(66.5115vw, -10px) scale(0.7033);
    transform: translate(66.5115vw, -10px) scale(0.7033);
    -webkit-animation: fall-87 115s -21s linear infinite;
    animation: fall-87 115s -21s linear infinite;
  }
  @keyframes fall-87 {
    64.528% {
      -webkit-transform: translate(64.2163vw, 774.336px) scale(0.7033);
      transform: translate(64.2163vw, 774.336px) scale(0.7033);
    }
    to {
      -webkit-transform: translate(65.3639vw, 100vh) scale(0.7033);
      transform: translate(65.3639vw, 100vh) scale(0.7033);
    }
  }
  .snow-1:nth-child(88) {
    opacity: 0.7432;
    -webkit-transform: translate(27.288vw, -10px) scale(0.0188);
    transform: translate(27.288vw, -10px) scale(0.0188);
    -webkit-animation: fall-88 145s -9s linear infinite;
    animation: fall-88 145s -9s linear infinite;
  }
  @keyframes fall-88 {
    35.703% {
      -webkit-transform: translate(21.0694vw, 428.436px) scale(0.0188);
      transform: translate(21.0694vw, 428.436px) scale(0.0188);
    }
    to {
      -webkit-transform: translate(24.1787vw, 100vh) scale(0.0188);
      transform: translate(24.1787vw, 100vh) scale(0.0188);
    }
  }
  .snow-1:nth-child(89) {
    opacity: 0.7617;
    -webkit-transform: translate(8.8163vw, -10px) scale(0.9401);
    transform: translate(8.8163vw, -10px) scale(0.9401);
    -webkit-animation: fall-89 145s -1s linear infinite;
    animation: fall-89 145s -1s linear infinite;
  }
  @keyframes fall-89 {
    45.547% {
      -webkit-transform: translate(10.9213vw, 546.564px) scale(0.9401);
      transform: translate(10.9213vw, 546.564px) scale(0.9401);
    }
    to {
      -webkit-transform: translate(9.8688vw, 100vh) scale(0.9401);
      transform: translate(9.8688vw, 100vh) scale(0.9401);
    }
  }
  .snow-1:nth-child(90) {
    opacity: 0.9196;
    -webkit-transform: translate(2.9058vw, -10px) scale(0.7);
    transform: translate(2.9058vw, -10px) scale(0.7);
    -webkit-animation: fall-90 150s -7s linear infinite;
    animation: fall-90 150s -7s linear infinite;
  }
  @keyframes fall-90 {
    51.003% {
      -webkit-transform: translate(10.0912vw, 612.036px) scale(0.7);
      transform: translate(10.0912vw, 612.036px) scale(0.7);
    }
    to {
      -webkit-transform: translate(6.4985vw, 100vh) scale(0.7);
      transform: translate(6.4985vw, 100vh) scale(0.7);
    }
  }
  .snow-1:nth-child(91) {
    opacity: 0.8817;
    -webkit-transform: translate(48.3022vw, -10px) scale(0.0489);
    transform: translate(48.3022vw, -10px) scale(0.0489);
    -webkit-animation: fall-91 90s -25s linear infinite;
    animation: fall-91 90s -25s linear infinite;
  }
  @keyframes fall-91 {
    59.36% {
      -webkit-transform: translate(41.4472vw, 712.32px) scale(0.0489);
      transform: translate(41.4472vw, 712.32px) scale(0.0489);
    }
    to {
      -webkit-transform: translate(44.8747vw, 100vh) scale(0.0489);
      transform: translate(44.8747vw, 100vh) scale(0.0489);
    }
  }
  .snow-1:nth-child(92) {
    opacity: 0.9183;
    -webkit-transform: translate(32.4836vw, -10px) scale(0.7372);
    transform: translate(32.4836vw, -10px) scale(0.7372);
    -webkit-animation: fall-92 65s -3s linear infinite;
    animation: fall-92 65s -3s linear infinite;
  }
  @keyframes fall-92 {
    72.347% {
      -webkit-transform: translate(32.8757vw, 868.164px) scale(0.7372);
      transform: translate(32.8757vw, 868.164px) scale(0.7372);
    }
    to {
      -webkit-transform: translate(32.67965vw, 100vh) scale(0.7372);
      transform: translate(32.67965vw, 100vh) scale(0.7372);
    }
  }
  .snow-1:nth-child(93) {
    opacity: 0.0282;
    -webkit-transform: translate(23.6733vw, -10px) scale(0.43);
    transform: translate(23.6733vw, -10px) scale(0.43);
    -webkit-animation: fall-93 60s -13s linear infinite;
    animation: fall-93 60s -13s linear infinite;
  }
  @keyframes fall-93 {
    32.56% {
      -webkit-transform: translate(13.8092vw, 390.72px) scale(0.43);
      transform: translate(13.8092vw, 390.72px) scale(0.43);
    }
    to {
      -webkit-transform: translate(18.74125vw, 100vh) scale(0.43);
      transform: translate(18.74125vw, 100vh) scale(0.43);
    }
  }
  .snow-1:nth-child(94) {
    opacity: 0.622;
    -webkit-transform: translate(31.8695vw, -10px) scale(0.7081);
    transform: translate(31.8695vw, -10px) scale(0.7081);
    -webkit-animation: fall-94 105s -15s linear infinite;
    animation: fall-94 105s -15s linear infinite;
  }
  @keyframes fall-94 {
    34.424% {
      -webkit-transform: translate(23.294vw, 413.088px) scale(0.7081);
      transform: translate(23.294vw, 413.088px) scale(0.7081);
    }
    to {
      -webkit-transform: translate(27.58175vw, 100vh) scale(0.7081);
      transform: translate(27.58175vw, 100vh) scale(0.7081);
    }
  }
  .snow-1:nth-child(95) {
    opacity: 0.1572;
    -webkit-transform: translate(34.0831vw, -10px) scale(0.5889);
    transform: translate(34.0831vw, -10px) scale(0.5889);
    -webkit-animation: fall-95 105s -8s linear infinite;
    animation: fall-95 105s -8s linear infinite;
  }
  @keyframes fall-95 {
    41.815% {
      -webkit-transform: translate(34.3909vw, 501.78px) scale(0.5889);
      transform: translate(34.3909vw, 501.78px) scale(0.5889);
    }
    to {
      -webkit-transform: translate(34.237vw, 100vh) scale(0.5889);
      transform: translate(34.237vw, 100vh) scale(0.5889);
    }
  }
  .snow-1:nth-child(96) {
    opacity: 0.5909;
    -webkit-transform: translate(16.1498vw, -10px) scale(0.0379);
    transform: translate(16.1498vw, -10px) scale(0.0379);
    -webkit-animation: fall-96 95s -5s linear infinite;
    animation: fall-96 95s -5s linear infinite;
  }
  @keyframes fall-96 {
    75.826% {
      -webkit-transform: translate(18.6766vw, 909.912px) scale(0.0379);
      transform: translate(18.6766vw, 909.912px) scale(0.0379);
    }
    to {
      -webkit-transform: translate(17.4132vw, 100vh) scale(0.0379);
      transform: translate(17.4132vw, 100vh) scale(0.0379);
    }
  }
  .snow-1:nth-child(97) {
    opacity: 0.9409;
    -webkit-transform: translate(22.0782vw, -10px) scale(0.4706);
    transform: translate(22.0782vw, -10px) scale(0.4706);
    -webkit-animation: fall-97 50s -5s linear infinite;
    animation: fall-97 50s -5s linear infinite;
  }
  @keyframes fall-97 {
    36.07% {
      -webkit-transform: translate(13.6737vw, 432.84px) scale(0.4706);
      transform: translate(13.6737vw, 432.84px) scale(0.4706);
    }
    to {
      -webkit-transform: translate(17.87595vw, 100vh) scale(0.4706);
      transform: translate(17.87595vw, 100vh) scale(0.4706);
    }
  }
  .snow-1:nth-child(98) {
    opacity: 0.5631;
    -webkit-transform: translate(35.2052vw, -10px) scale(0.4519);
    transform: translate(35.2052vw, -10px) scale(0.4519);
    -webkit-animation: fall-98 110s -27s linear infinite;
    animation: fall-98 110s -27s linear infinite;
  }
  @keyframes fall-98 {
    52.875% {
      -webkit-transform: translate(40.0683vw, 634.5px) scale(0.4519);
      transform: translate(40.0683vw, 634.5px) scale(0.4519);
    }
    to {
      -webkit-transform: translate(37.63675vw, 100vh) scale(0.4519);
      transform: translate(37.63675vw, 100vh) scale(0.4519);
    }
  }
  .snow-1:nth-child(99) {
    opacity: 0.7594;
    -webkit-transform: translate(43.2395vw, -10px) scale(0.21);
    transform: translate(43.2395vw, -10px) scale(0.21);
    -webkit-animation: fall-99 90s -21s linear infinite;
    animation: fall-99 90s -21s linear infinite;
  }
  @keyframes fall-99 {
    38.485% {
      -webkit-transform: translate(37.7022vw, 461.82px) scale(0.21);
      transform: translate(37.7022vw, 461.82px) scale(0.21);
    }
    to {
      -webkit-transform: translate(40.47085vw, 100vh) scale(0.21);
      transform: translate(40.47085vw, 100vh) scale(0.21);
    }
  }
  .snow-1:nth-child(100) {
    opacity: 0.5671;
    -webkit-transform: translate(49.9698vw, -10px) scale(0.4468);
    transform: translate(49.9698vw, -10px) scale(0.4468);
    -webkit-animation: fall-100 110s -27s linear infinite;
    animation: fall-100 110s -27s linear infinite;
  }
  @keyframes fall-100 {
    37.224% {
      -webkit-transform: translate(43.1548vw, 446.688px) scale(0.4468);
      transform: translate(43.1548vw, 446.688px) scale(0.4468);
    }
    to {
      -webkit-transform: translate(46.5623vw, 100vh) scale(0.4468);
      transform: translate(46.5623vw, 100vh) scale(0.4468);
    }
  }
  .snow-1:nth-child(101) {
    opacity: 0.2929;
    -webkit-transform: translate(6.7147vw, -10px) scale(0.6524);
    transform: translate(6.7147vw, -10px) scale(0.6524);
    -webkit-animation: fall-101 95s -20s linear infinite;
    animation: fall-101 95s -20s linear infinite;
  }
  @keyframes fall-101 {
    59.348% {
      -webkit-transform: translate(13.1443vw, 712.176px) scale(0.6524);
      transform: translate(13.1443vw, 712.176px) scale(0.6524);
    }
    to {
      -webkit-transform: translate(9.9295vw, 100vh) scale(0.6524);
      transform: translate(9.9295vw, 100vh) scale(0.6524);
    }
  }
  .snow-1:nth-child(102) {
    opacity: 0.6492;
    -webkit-transform: translate(63.7032vw, -10px) scale(0.6991);
    transform: translate(63.7032vw, -10px) scale(0.6991);
    -webkit-animation: fall-102 60s -11s linear infinite;
    animation: fall-102 60s -11s linear infinite;
  }
  @keyframes fall-102 {
    40.393% {
      -webkit-transform: translate(59.3606vw, 484.716px) scale(0.6991);
      transform: translate(59.3606vw, 484.716px) scale(0.6991);
    }
    to {
      -webkit-transform: translate(61.5319vw, 100vh) scale(0.6991);
      transform: translate(61.5319vw, 100vh) scale(0.6991);
    }
  }
  .snow-1:nth-child(103) {
    opacity: 0.7002;
    -webkit-transform: translate(2.1117vw, -10px) scale(0.7587);
    transform: translate(2.1117vw, -10px) scale(0.7587);
    -webkit-animation: fall-103 55s -15s linear infinite;
    animation: fall-103 55s -15s linear infinite;
  }
  @keyframes fall-103 {
    43.777% {
      -webkit-transform: translate(-0.0325vw, 525.324px) scale(0.7587);
      transform: translate(-0.0325vw, 525.324px) scale(0.7587);
    }
    to {
      -webkit-transform: translate(1.0396vw, 100vh) scale(0.7587);
      transform: translate(1.0396vw, 100vh) scale(0.7587);
    }
  }
  .snow-1:nth-child(104) {
    opacity: 0.6247;
    -webkit-transform: translate(87.1152vw, -10px) scale(0.3815);
    transform: translate(87.1152vw, -10px) scale(0.3815);
    -webkit-animation: fall-104 105s -15s linear infinite;
    animation: fall-104 105s -15s linear infinite;
  }
  @keyframes fall-104 {
    78.456% {
      -webkit-transform: translate(77.5425vw, 941.472px) scale(0.3815);
      transform: translate(77.5425vw, 941.472px) scale(0.3815);
    }
    to {
      -webkit-transform: translate(82.32885vw, 100vh) scale(0.3815);
      transform: translate(82.32885vw, 100vh) scale(0.3815);
    }
  }
  .snow-1:nth-child(105) {
    opacity: 0.6775;
    -webkit-transform: translate(13.7637vw, -10px) scale(0.6918);
    transform: translate(13.7637vw, -10px) scale(0.6918);
    -webkit-animation: fall-105 55s -18s linear infinite;
    animation: fall-105 55s -18s linear infinite;
  }
  @keyframes fall-105 {
    32.617% {
      -webkit-transform: translate(21.4046vw, 391.404px) scale(0.6918);
      transform: translate(21.4046vw, 391.404px) scale(0.6918);
    }
    to {
      -webkit-transform: translate(17.58415vw, 100vh) scale(0.6918);
      transform: translate(17.58415vw, 100vh) scale(0.6918);
    }
  }
  .snow-1:nth-child(106) {
    opacity: 0.5143;
    -webkit-transform: translate(40.9512vw, -10px) scale(0.9234);
    transform: translate(40.9512vw, -10px) scale(0.9234);
    -webkit-animation: fall-106 130s -14s linear infinite;
    animation: fall-106 130s -14s linear infinite;
  }
  @keyframes fall-106 {
    79.149% {
      -webkit-transform: translate(40.9712vw, 949.788px) scale(0.9234);
      transform: translate(40.9712vw, 949.788px) scale(0.9234);
    }
    to {
      -webkit-transform: translate(40.9612vw, 100vh) scale(0.9234);
      transform: translate(40.9612vw, 100vh) scale(0.9234);
    }
  }
  .snow-1:nth-child(107) {
    opacity: 0.2796;
    -webkit-transform: translate(11.9117vw, -10px) scale(0.0913);
    transform: translate(11.9117vw, -10px) scale(0.0913);
    -webkit-animation: fall-107 65s -18s linear infinite;
    animation: fall-107 65s -18s linear infinite;
  }
  @keyframes fall-107 {
    35.755% {
      -webkit-transform: translate(10.0076vw, 429.06px) scale(0.0913);
      transform: translate(10.0076vw, 429.06px) scale(0.0913);
    }
    to {
      -webkit-transform: translate(10.95965vw, 100vh) scale(0.0913);
      transform: translate(10.95965vw, 100vh) scale(0.0913);
    }
  }
  .snow-1:nth-child(108) {
    opacity: 0.612;
    -webkit-transform: translate(18.6513vw, -10px) scale(0.5506);
    transform: translate(18.6513vw, -10px) scale(0.5506);
    -webkit-animation: fall-108 130s -25s linear infinite;
    animation: fall-108 130s -25s linear infinite;
  }
  @keyframes fall-108 {
    78.62% {
      -webkit-transform: translate(25.7509vw, 943.44px) scale(0.5506);
      transform: translate(25.7509vw, 943.44px) scale(0.5506);
    }
    to {
      -webkit-transform: translate(22.2011vw, 100vh) scale(0.5506);
      transform: translate(22.2011vw, 100vh) scale(0.5506);
    }
  }
  .snow-1:nth-child(109) {
    opacity: 0.6271;
    -webkit-transform: translate(42.1761vw, -10px) scale(0.7423);
    transform: translate(42.1761vw, -10px) scale(0.7423);
    -webkit-animation: fall-109 65s -17s linear infinite;
    animation: fall-109 65s -17s linear infinite;
  }
  @keyframes fall-109 {
    65.983% {
      -webkit-transform: translate(42.1707vw, 791.796px) scale(0.7423);
      transform: translate(42.1707vw, 791.796px) scale(0.7423);
    }
    to {
      -webkit-transform: translate(42.1734vw, 100vh) scale(0.7423);
      transform: translate(42.1734vw, 100vh) scale(0.7423);
    }
  }
  .snow-1:nth-child(110) {
    opacity: 0.8685;
    -webkit-transform: translate(9.6319vw, -10px) scale(0.4671);
    transform: translate(9.6319vw, -10px) scale(0.4671);
    -webkit-animation: fall-110 145s -12s linear infinite;
    animation: fall-110 145s -12s linear infinite;
  }
  @keyframes fall-110 {
    63.057% {
      -webkit-transform: translate(8.6094vw, 756.684px) scale(0.4671);
      transform: translate(8.6094vw, 756.684px) scale(0.4671);
    }
    to {
      -webkit-transform: translate(9.12065vw, 100vh) scale(0.4671);
      transform: translate(9.12065vw, 100vh) scale(0.4671);
    }
  }
  .snow-1:nth-child(111) {
    opacity: 0.6712;
    -webkit-transform: translate(77.807vw, -10px) scale(0.4944);
    transform: translate(77.807vw, -10px) scale(0.4944);
    -webkit-animation: fall-111 60s -3s linear infinite;
    animation: fall-111 60s -3s linear infinite;
  }
  @keyframes fall-111 {
    70.157% {
      -webkit-transform: translate(84.2069vw, 841.884px) scale(0.4944);
      transform: translate(84.2069vw, 841.884px) scale(0.4944);
    }
    to {
      -webkit-transform: translate(81.00695vw, 100vh) scale(0.4944);
      transform: translate(81.00695vw, 100vh) scale(0.4944);
    }
  }
  .snow-1:nth-child(112) {
    opacity: 0.4416;
    -webkit-transform: translate(19.8232vw, -10px) scale(0.3299);
    transform: translate(19.8232vw, -10px) scale(0.3299);
    -webkit-animation: fall-112 65s -25s linear infinite;
    animation: fall-112 65s -25s linear infinite;
  }
  @keyframes fall-112 {
    75.072% {
      -webkit-transform: translate(10.0485vw, 900.864px) scale(0.3299);
      transform: translate(10.0485vw, 900.864px) scale(0.3299);
    }
    to {
      -webkit-transform: translate(14.93585vw, 100vh) scale(0.3299);
      transform: translate(14.93585vw, 100vh) scale(0.3299);
    }
  }
  .snow-1:nth-child(113) {
    opacity: 0.9308;
    -webkit-transform: translate(86.364vw, -10px) scale(0.4112);
    transform: translate(86.364vw, -10px) scale(0.4112);
    -webkit-animation: fall-113 95s -25s linear infinite;
    animation: fall-113 95s -25s linear infinite;
  }
  @keyframes fall-113 {
    43.187% {
      -webkit-transform: translate(92.7634vw, 518.244px) scale(0.4112);
      transform: translate(92.7634vw, 518.244px) scale(0.4112);
    }
    to {
      -webkit-transform: translate(89.5637vw, 100vh) scale(0.4112);
      transform: translate(89.5637vw, 100vh) scale(0.4112);
    }
  }
  .snow-1:nth-child(114) {
    opacity: 0.7966;
    -webkit-transform: translate(38.9568vw, -10px) scale(0.5624);
    transform: translate(38.9568vw, -10px) scale(0.5624);
    -webkit-animation: fall-114 80s -3s linear infinite;
    animation: fall-114 80s -3s linear infinite;
  }
  @keyframes fall-114 {
    60.45% {
      -webkit-transform: translate(44.9226vw, 725.4px) scale(0.5624);
      transform: translate(44.9226vw, 725.4px) scale(0.5624);
    }
    to {
      -webkit-transform: translate(41.9397vw, 100vh) scale(0.5624);
      transform: translate(41.9397vw, 100vh) scale(0.5624);
    }
  }
  .snow-1:nth-child(115) {
    opacity: 0.6835;
    -webkit-transform: translate(72.6413vw, -10px) scale(0.1511);
    transform: translate(72.6413vw, -10px) scale(0.1511);
    -webkit-animation: fall-115 140s -21s linear infinite;
    animation: fall-115 140s -21s linear infinite;
  }
  @keyframes fall-115 {
    74.084% {
      -webkit-transform: translate(72.1455vw, 889.008px) scale(0.1511);
      transform: translate(72.1455vw, 889.008px) scale(0.1511);
    }
    to {
      -webkit-transform: translate(72.3934vw, 100vh) scale(0.1511);
      transform: translate(72.3934vw, 100vh) scale(0.1511);
    }
  }
  .snow-1:nth-child(116) {
    opacity: 0.5543;
    -webkit-transform: translate(4.311vw, -10px) scale(0.0558);
    transform: translate(4.311vw, -10px) scale(0.0558);
    -webkit-animation: fall-116 125s -18s linear infinite;
    animation: fall-116 125s -18s linear infinite;
  }
  @keyframes fall-116 {
    54.118% {
      -webkit-transform: translate(-0.6119vw, 649.416px) scale(0.0558);
      transform: translate(-0.6119vw, 649.416px) scale(0.0558);
    }
    to {
      -webkit-transform: translate(1.84955vw, 100vh) scale(0.0558);
      transform: translate(1.84955vw, 100vh) scale(0.0558);
    }
  }
  .snow-1:nth-child(117) {
    opacity: 0.1867;
    -webkit-transform: translate(51.0562vw, -10px) scale(0.5908);
    transform: translate(51.0562vw, -10px) scale(0.5908);
    -webkit-animation: fall-117 150s -22s linear infinite;
    animation: fall-117 150s -22s linear infinite;
  }
  @keyframes fall-117 {
    79.181% {
      -webkit-transform: translate(41.8459vw, 950.172px) scale(0.5908);
      transform: translate(41.8459vw, 950.172px) scale(0.5908);
    }
    to {
      -webkit-transform: translate(46.45105vw, 100vh) scale(0.5908);
      transform: translate(46.45105vw, 100vh) scale(0.5908);
    }
  }
  .snow-1:nth-child(118) {
    opacity: 0.1025;
    -webkit-transform: translate(78.1791vw, -10px) scale(0.3667);
    transform: translate(78.1791vw, -10px) scale(0.3667);
    -webkit-animation: fall-118 125s -12s linear infinite;
    animation: fall-118 125s -12s linear infinite;
  }
  @keyframes fall-118 {
    64.444% {
      -webkit-transform: translate(70.4592vw, 773.328px) scale(0.3667);
      transform: translate(70.4592vw, 773.328px) scale(0.3667);
    }
    to {
      -webkit-transform: translate(74.31915vw, 100vh) scale(0.3667);
      transform: translate(74.31915vw, 100vh) scale(0.3667);
    }
  }
  .snow-1:nth-child(119) {
    opacity: 0.4632;
    -webkit-transform: translate(47.0784vw, -10px) scale(0.9723);
    transform: translate(47.0784vw, -10px) scale(0.9723);
    -webkit-animation: fall-119 65s -21s linear infinite;
    animation: fall-119 65s -21s linear infinite;
  }
  @keyframes fall-119 {
    38.31% {
      -webkit-transform: translate(39.0826vw, 459.72px) scale(0.9723);
      transform: translate(39.0826vw, 459.72px) scale(0.9723);
    }
    to {
      -webkit-transform: translate(43.0805vw, 100vh) scale(0.9723);
      transform: translate(43.0805vw, 100vh) scale(0.9723);
    }
  }
  .snow-1:nth-child(120) {
    opacity: 0.2057;
    -webkit-transform: translate(15.6676vw, -10px) scale(0.0622);
    transform: translate(15.6676vw, -10px) scale(0.0622);
    -webkit-animation: fall-120 50s -10s linear infinite;
    animation: fall-120 50s -10s linear infinite;
  }
  @keyframes fall-120 {
    70.541% {
      -webkit-transform: translate(15.4246vw, 846.492px) scale(0.0622);
      transform: translate(15.4246vw, 846.492px) scale(0.0622);
    }
    to {
      -webkit-transform: translate(15.5461vw, 100vh) scale(0.0622);
      transform: translate(15.5461vw, 100vh) scale(0.0622);
    }
  }
  .snow-1:nth-child(121) {
    opacity: 0.423;
    -webkit-transform: translate(88.2676vw, -10px) scale(0.0587);
    transform: translate(88.2676vw, -10px) scale(0.0587);
    -webkit-animation: fall-121 80s -23s linear infinite;
    animation: fall-121 80s -23s linear infinite;
  }
  @keyframes fall-121 {
    38.738% {
      -webkit-transform: translate(95.0089vw, 464.856px) scale(0.0587);
      transform: translate(95.0089vw, 464.856px) scale(0.0587);
    }
    to {
      -webkit-transform: translate(91.63825vw, 100vh) scale(0.0587);
      transform: translate(91.63825vw, 100vh) scale(0.0587);
    }
  }
  .snow-1:nth-child(122) {
    opacity: 0.7516;
    -webkit-transform: translate(86.7626vw, -10px) scale(0.2812);
    transform: translate(86.7626vw, -10px) scale(0.2812);
    -webkit-animation: fall-122 65s -12s linear infinite;
    animation: fall-122 65s -12s linear infinite;
  }
  @keyframes fall-122 {
    72.505% {
      -webkit-transform: translate(79.1014vw, 870.06px) scale(0.2812);
      transform: translate(79.1014vw, 870.06px) scale(0.2812);
    }
    to {
      -webkit-transform: translate(82.932vw, 100vh) scale(0.2812);
      transform: translate(82.932vw, 100vh) scale(0.2812);
    }
  }
  .snow-1:nth-child(123) {
    opacity: 0.0706;
    -webkit-transform: translate(44.7678vw, -10px) scale(0.4779);
    transform: translate(44.7678vw, -10px) scale(0.4779);
    -webkit-animation: fall-123 125s -16s linear infinite;
    animation: fall-123 125s -16s linear infinite;
  }
  @keyframes fall-123 {
    40.843% {
      -webkit-transform: translate(41.3362vw, 490.116px) scale(0.4779);
      transform: translate(41.3362vw, 490.116px) scale(0.4779);
    }
    to {
      -webkit-transform: translate(43.052vw, 100vh) scale(0.4779);
      transform: translate(43.052vw, 100vh) scale(0.4779);
    }
  }
  .snow-1:nth-child(124) {
    opacity: 0.3153;
    -webkit-transform: translate(29.2678vw, -10px) scale(0.0623);
    transform: translate(29.2678vw, -10px) scale(0.0623);
    -webkit-animation: fall-124 150s -12s linear infinite;
    animation: fall-124 150s -12s linear infinite;
  }
  @keyframes fall-124 {
    77.629% {
      -webkit-transform: translate(19.4129vw, 931.548px) scale(0.0623);
      transform: translate(19.4129vw, 931.548px) scale(0.0623);
    }
    to {
      -webkit-transform: translate(24.34035vw, 100vh) scale(0.0623);
      transform: translate(24.34035vw, 100vh) scale(0.0623);
    }
  }
  .snow-1:nth-child(125) {
    opacity: 0.0293;
    -webkit-transform: translate(47.4772vw, -10px) scale(0.8467);
    transform: translate(47.4772vw, -10px) scale(0.8467);
    -webkit-animation: fall-125 125s -25s linear infinite;
    animation: fall-125 125s -25s linear infinite;
  }
  @keyframes fall-125 {
    68.677% {
      -webkit-transform: translate(53.982vw, 824.124px) scale(0.8467);
      transform: translate(53.982vw, 824.124px) scale(0.8467);
    }
    to {
      -webkit-transform: translate(50.7296vw, 100vh) scale(0.8467);
      transform: translate(50.7296vw, 100vh) scale(0.8467);
    }
  }
  .snow-1:nth-child(126) {
    opacity: 0.627;
    -webkit-transform: translate(89.0163vw, -10px) scale(0.381);
    transform: translate(89.0163vw, -10px) scale(0.381);
    -webkit-animation: fall-126 120s -14s linear infinite;
    animation: fall-126 120s -14s linear infinite;
  }
  @keyframes fall-126 {
    43.114% {
      -webkit-transform: translate(98.9212vw, 517.368px) scale(0.381);
      transform: translate(98.9212vw, 517.368px) scale(0.381);
    }
    to {
      -webkit-transform: translate(93.96875vw, 100vh) scale(0.381);
      transform: translate(93.96875vw, 100vh) scale(0.381);
    }
  }
  .snow-1:nth-child(127) {
    opacity: 0.3349;
    -webkit-transform: translate(76.3973vw, -10px) scale(0.9078);
    transform: translate(76.3973vw, -10px) scale(0.9078);
    -webkit-animation: fall-127 95s -3s linear infinite;
    animation: fall-127 95s -3s linear infinite;
  }
  @keyframes fall-127 {
    57.437% {
      -webkit-transform: translate(70.3511vw, 689.244px) scale(0.9078);
      transform: translate(70.3511vw, 689.244px) scale(0.9078);
    }
    to {
      -webkit-transform: translate(73.3742vw, 100vh) scale(0.9078);
      transform: translate(73.3742vw, 100vh) scale(0.9078);
    }
  }
  .snow-1:nth-child(128) {
    opacity: 0.1018;
    -webkit-transform: translate(48.2644vw, -10px) scale(0.4375);
    transform: translate(48.2644vw, -10px) scale(0.4375);
    -webkit-animation: fall-128 145s -20s linear infinite;
    animation: fall-128 145s -20s linear infinite;
  }
  @keyframes fall-128 {
    78.581% {
      -webkit-transform: translate(46.1014vw, 942.972px) scale(0.4375);
      transform: translate(46.1014vw, 942.972px) scale(0.4375);
    }
    to {
      -webkit-transform: translate(47.1829vw, 100vh) scale(0.4375);
      transform: translate(47.1829vw, 100vh) scale(0.4375);
    }
  }
  .snow-1:nth-child(129) {
    opacity: 0.407;
    -webkit-transform: translate(41.0233vw, -10px) scale(0.6553);
    transform: translate(41.0233vw, -10px) scale(0.6553);
    -webkit-animation: fall-129 90s -7s linear infinite;
    animation: fall-129 90s -7s linear infinite;
  }
  @keyframes fall-129 {
    47.519% {
      -webkit-transform: translate(42.0691vw, 570.228px) scale(0.6553);
      transform: translate(42.0691vw, 570.228px) scale(0.6553);
    }
    to {
      -webkit-transform: translate(41.5462vw, 100vh) scale(0.6553);
      transform: translate(41.5462vw, 100vh) scale(0.6553);
    }
  }
  .snow-1:nth-child(130) {
    opacity: 0.7203;
    -webkit-transform: translate(76.7162vw, -10px) scale(0.4827);
    transform: translate(76.7162vw, -10px) scale(0.4827);
    -webkit-animation: fall-130 100s -22s linear infinite;
    animation: fall-130 100s -22s linear infinite;
  }
  @keyframes fall-130 {
    45.965% {
      -webkit-transform: translate(71.665vw, 551.58px) scale(0.4827);
      transform: translate(71.665vw, 551.58px) scale(0.4827);
    }
    to {
      -webkit-transform: translate(74.1906vw, 100vh) scale(0.4827);
      transform: translate(74.1906vw, 100vh) scale(0.4827);
    }
  }
  .snow-1:nth-child(131) {
    opacity: 0.2654;
    -webkit-transform: translate(89.6851vw, -10px) scale(0.966);
    transform: translate(89.6851vw, -10px) scale(0.966);
    -webkit-animation: fall-131 85s -21s linear infinite;
    animation: fall-131 85s -21s linear infinite;
  }
  @keyframes fall-131 {
    55.235% {
      -webkit-transform: translate(94.5824vw, 662.82px) scale(0.966);
      transform: translate(94.5824vw, 662.82px) scale(0.966);
    }
    to {
      -webkit-transform: translate(92.13375vw, 100vh) scale(0.966);
      transform: translate(92.13375vw, 100vh) scale(0.966);
    }
  }
  .snow-1:nth-child(132) {
    opacity: 0.9675;
    -webkit-transform: translate(42.5625vw, -10px) scale(0.4874);
    transform: translate(42.5625vw, -10px) scale(0.4874);
    -webkit-animation: fall-132 75s -7s linear infinite;
    animation: fall-132 75s -7s linear infinite;
  }
  @keyframes fall-132 {
    72.48% {
      -webkit-transform: translate(44.9357vw, 869.76px) scale(0.4874);
      transform: translate(44.9357vw, 869.76px) scale(0.4874);
    }
    to {
      -webkit-transform: translate(43.7491vw, 100vh) scale(0.4874);
      transform: translate(43.7491vw, 100vh) scale(0.4874);
    }
  }
  .snow-1:nth-child(133) {
    opacity: 0.6279;
    -webkit-transform: translate(29.4102vw, -10px) scale(0.7062);
    transform: translate(29.4102vw, -10px) scale(0.7062);
    -webkit-animation: fall-133 145s -28s linear infinite;
    animation: fall-133 145s -28s linear infinite;
  }
  @keyframes fall-133 {
    33.207% {
      -webkit-transform: translate(26.4574vw, 398.484px) scale(0.7062);
      transform: translate(26.4574vw, 398.484px) scale(0.7062);
    }
    to {
      -webkit-transform: translate(27.9338vw, 100vh) scale(0.7062);
      transform: translate(27.9338vw, 100vh) scale(0.7062);
    }
  }
  .snow-1:nth-child(134) {
    opacity: 0.4184;
    -webkit-transform: translate(19.535vw, -10px) scale(0.4593);
    transform: translate(19.535vw, -10px) scale(0.4593);
    -webkit-animation: fall-134 100s -2s linear infinite;
    animation: fall-134 100s -2s linear infinite;
  }
  @keyframes fall-134 {
    53.019% {
      -webkit-transform: translate(24.5369vw, 636.228px) scale(0.4593);
      transform: translate(24.5369vw, 636.228px) scale(0.4593);
    }
    to {
      -webkit-transform: translate(22.03595vw, 100vh) scale(0.4593);
      transform: translate(22.03595vw, 100vh) scale(0.4593);
    }
  }
  .snow-1:nth-child(135) {
    opacity: 0.7438;
    -webkit-transform: translate(71.5155vw, -10px) scale(0.9381);
    transform: translate(71.5155vw, -10px) scale(0.9381);
    -webkit-animation: fall-135 70s -22s linear infinite;
    animation: fall-135 70s -22s linear infinite;
  }
  @keyframes fall-135 {
    37.297% {
      -webkit-transform: translate(64.3086vw, 447.564px) scale(0.9381);
      transform: translate(64.3086vw, 447.564px) scale(0.9381);
    }
    to {
      -webkit-transform: translate(67.91205vw, 100vh) scale(0.9381);
      transform: translate(67.91205vw, 100vh) scale(0.9381);
    }
  }
  .snow-1:nth-child(136) {
    opacity: 0.6226;
    -webkit-transform: translate(38.4125vw, -10px) scale(0.7997);
    transform: translate(38.4125vw, -10px) scale(0.7997);
    -webkit-animation: fall-136 50s -4s linear infinite;
    animation: fall-136 50s -4s linear infinite;
  }
  @keyframes fall-136 {
    79.729% {
      -webkit-transform: translate(43.2398vw, 956.748px) scale(0.7997);
      transform: translate(43.2398vw, 956.748px) scale(0.7997);
    }
    to {
      -webkit-transform: translate(40.82615vw, 100vh) scale(0.7997);
      transform: translate(40.82615vw, 100vh) scale(0.7997);
    }
  }
  .snow-1:nth-child(137) {
    opacity: 0.2432;
    -webkit-transform: translate(85.3883vw, -10px) scale(0.1436);
    transform: translate(85.3883vw, -10px) scale(0.1436);
    -webkit-animation: fall-137 75s -15s linear infinite;
    animation: fall-137 75s -15s linear infinite;
  }
  @keyframes fall-137 {
    52.762% {
      -webkit-transform: translate(77.9568vw, 633.144px) scale(0.1436);
      transform: translate(77.9568vw, 633.144px) scale(0.1436);
    }
    to {
      -webkit-transform: translate(81.67255vw, 100vh) scale(0.1436);
      transform: translate(81.67255vw, 100vh) scale(0.1436);
    }
  }
  .snow-1:nth-child(138) {
    opacity: 0.0325;
    -webkit-transform: translate(77.8954vw, -10px) scale(0.6758);
    transform: translate(77.8954vw, -10px) scale(0.6758);
    -webkit-animation: fall-138 130s -28s linear infinite;
    animation: fall-138 130s -28s linear infinite;
  }
  @keyframes fall-138 {
    31.139% {
      -webkit-transform: translate(69.6703vw, 373.668px) scale(0.6758);
      transform: translate(69.6703vw, 373.668px) scale(0.6758);
    }
    to {
      -webkit-transform: translate(73.78285vw, 100vh) scale(0.6758);
      transform: translate(73.78285vw, 100vh) scale(0.6758);
    }
  }
  .snow-1:nth-child(139) {
    opacity: 0.9698;
    -webkit-transform: translate(88.979vw, -10px) scale(0.0145);
    transform: translate(88.979vw, -10px) scale(0.0145);
    -webkit-animation: fall-139 125s -10s linear infinite;
    animation: fall-139 125s -10s linear infinite;
  }
  @keyframes fall-139 {
    57.677% {
      -webkit-transform: translate(82.818vw, 692.124px) scale(0.0145);
      transform: translate(82.818vw, 692.124px) scale(0.0145);
    }
    to {
      -webkit-transform: translate(85.8985vw, 100vh) scale(0.0145);
      transform: translate(85.8985vw, 100vh) scale(0.0145);
    }
  }
  .snow-1:nth-child(140) {
    opacity: 0.3097;
    -webkit-transform: translate(81.8771vw, -10px) scale(0.9321);
    transform: translate(81.8771vw, -10px) scale(0.9321);
    -webkit-animation: fall-140 60s -23s linear infinite;
    animation: fall-140 60s -23s linear infinite;
  }
  @keyframes fall-140 {
    75.938% {
      -webkit-transform: translate(74.9347vw, 911.256px) scale(0.9321);
      transform: translate(74.9347vw, 911.256px) scale(0.9321);
    }
    to {
      -webkit-transform: translate(78.4059vw, 100vh) scale(0.9321);
      transform: translate(78.4059vw, 100vh) scale(0.9321);
    }
  }
  .snow-1:nth-child(141) {
    opacity: 0.4967;
    -webkit-transform: translate(86.5461vw, -10px) scale(0.0959);
    transform: translate(86.5461vw, -10px) scale(0.0959);
    -webkit-animation: fall-141 125s -1s linear infinite;
    animation: fall-141 125s -1s linear infinite;
  }
  @keyframes fall-141 {
    34.085% {
      -webkit-transform: translate(87.8535vw, 409.02px) scale(0.0959);
      transform: translate(87.8535vw, 409.02px) scale(0.0959);
    }
    to {
      -webkit-transform: translate(87.1998vw, 100vh) scale(0.0959);
      transform: translate(87.1998vw, 100vh) scale(0.0959);
    }
  }
  .snow-1:nth-child(142) {
    opacity: 0.839;
    -webkit-transform: translate(64.8812vw, -10px) scale(0.0238);
    transform: translate(64.8812vw, -10px) scale(0.0238);
    -webkit-animation: fall-142 135s -25s linear infinite;
    animation: fall-142 135s -25s linear infinite;
  }
  @keyframes fall-142 {
    53.822% {
      -webkit-transform: translate(63.6623vw, 645.864px) scale(0.0238);
      transform: translate(63.6623vw, 645.864px) scale(0.0238);
    }
    to {
      -webkit-transform: translate(64.27175vw, 100vh) scale(0.0238);
      transform: translate(64.27175vw, 100vh) scale(0.0238);
    }
  }
  .snow-1:nth-child(143) {
    opacity: 0.8037;
    -webkit-transform: translate(52.1672vw, -10px) scale(0.6956);
    transform: translate(52.1672vw, -10px) scale(0.6956);
    -webkit-animation: fall-143 85s -10s linear infinite;
    animation: fall-143 85s -10s linear infinite;
  }
  @keyframes fall-143 {
    75.257% {
      -webkit-transform: translate(42.8689vw, 903.084px) scale(0.6956);
      transform: translate(42.8689vw, 903.084px) scale(0.6956);
    }
    to {
      -webkit-transform: translate(47.51805vw, 100vh) scale(0.6956);
      transform: translate(47.51805vw, 100vh) scale(0.6956);
    }
  }
  .snow-1:nth-child(144) {
    opacity: 0.1709;
    -webkit-transform: translate(43.2061vw, -10px) scale(0.4856);
    transform: translate(43.2061vw, -10px) scale(0.4856);
    -webkit-animation: fall-144 95s -17s linear infinite;
    animation: fall-144 95s -17s linear infinite;
  }
  @keyframes fall-144 {
    31.124% {
      -webkit-transform: translate(45.8989vw, 373.488px) scale(0.4856);
      transform: translate(45.8989vw, 373.488px) scale(0.4856);
    }
    to {
      -webkit-transform: translate(44.5525vw, 100vh) scale(0.4856);
      transform: translate(44.5525vw, 100vh) scale(0.4856);
    }
  }
  .snow-1:nth-child(145) {
    opacity: 0.9994;
    -webkit-transform: translate(22.6569vw, -10px) scale(0.9693);
    transform: translate(22.6569vw, -10px) scale(0.9693);
    -webkit-animation: fall-145 70s -1s linear infinite;
    animation: fall-145 70s -1s linear infinite;
  }
  @keyframes fall-145 {
    68.802% {
      -webkit-transform: translate(13.4299vw, 825.624px) scale(0.9693);
      transform: translate(13.4299vw, 825.624px) scale(0.9693);
    }
    to {
      -webkit-transform: translate(18.0434vw, 100vh) scale(0.9693);
      transform: translate(18.0434vw, 100vh) scale(0.9693);
    }
  }
  .snow-1:nth-child(146) {
    opacity: 0.5997;
    -webkit-transform: translate(2.0755vw, -10px) scale(0.6161);
    transform: translate(2.0755vw, -10px) scale(0.6161);
    -webkit-animation: fall-146 65s -13s linear infinite;
    animation: fall-146 65s -13s linear infinite;
  }
  @keyframes fall-146 {
    60.864% {
      -webkit-transform: translate(-2.4309vw, 730.368px) scale(0.6161);
      transform: translate(-2.4309vw, 730.368px) scale(0.6161);
    }
    to {
      -webkit-transform: translate(-0.1777vw, 100vh) scale(0.6161);
      transform: translate(-0.1777vw, 100vh) scale(0.6161);
    }
  }
  .snow-1:nth-child(147) {
    opacity: 0.2295;
    -webkit-transform: translate(25.1236vw, -10px) scale(0.9608);
    transform: translate(25.1236vw, -10px) scale(0.9608);
    -webkit-animation: fall-147 115s -7s linear infinite;
    animation: fall-147 115s -7s linear infinite;
  }
  @keyframes fall-147 {
    49.076% {
      -webkit-transform: translate(32.9158vw, 588.912px) scale(0.9608);
      transform: translate(32.9158vw, 588.912px) scale(0.9608);
    }
    to {
      -webkit-transform: translate(29.0197vw, 100vh) scale(0.9608);
      transform: translate(29.0197vw, 100vh) scale(0.9608);
    }
  }
  .snow-1:nth-child(148) {
    opacity: 0.1546;
    -webkit-transform: translate(33.3175vw, -10px) scale(0.6634);
    transform: translate(33.3175vw, -10px) scale(0.6634);
    -webkit-animation: fall-148 130s -21s linear infinite;
    animation: fall-148 130s -21s linear infinite;
  }
  @keyframes fall-148 {
    59.432% {
      -webkit-transform: translate(27.8805vw, 713.184px) scale(0.6634);
      transform: translate(27.8805vw, 713.184px) scale(0.6634);
    }
    to {
      -webkit-transform: translate(30.599vw, 100vh) scale(0.6634);
      transform: translate(30.599vw, 100vh) scale(0.6634);
    }
  }
  .snow-1:nth-child(149) {
    opacity: 0.6602;
    -webkit-transform: translate(82.0602vw, -10px) scale(0.6685);
    transform: translate(82.0602vw, -10px) scale(0.6685);
    -webkit-animation: fall-149 130s -1s linear infinite;
    animation: fall-149 130s -1s linear infinite;
  }
  @keyframes fall-149 {
    54.393% {
      -webkit-transform: translate(81.4241vw, 652.716px) scale(0.6685);
      transform: translate(81.4241vw, 652.716px) scale(0.6685);
    }
    to {
      -webkit-transform: translate(81.74215vw, 100vh) scale(0.6685);
      transform: translate(81.74215vw, 100vh) scale(0.6685);
    }
  }
  .snow-1:nth-child(150) {
    opacity: 0.6604;
    -webkit-transform: translate(84.3638vw, -10px) scale(0.3791);
    transform: translate(84.3638vw, -10px) scale(0.3791);
    -webkit-animation: fall-150 140s -8s linear infinite;
    animation: fall-150 140s -8s linear infinite;
  }
  @keyframes fall-150 {
    73.582% {
      -webkit-transform: translate(78.0804vw, 882.984px) scale(0.3791);
      transform: translate(78.0804vw, 882.984px) scale(0.3791);
    }
    to {
      -webkit-transform: translate(81.2221vw, 100vh) scale(0.3791);
      transform: translate(81.2221vw, 100vh) scale(0.3791);
    }
  }
  .snow-1:nth-child(151) {
    opacity: 0.981;
    -webkit-transform: translate(75.4791vw, -10px) scale(0.4708);
    transform: translate(75.4791vw, -10px) scale(0.4708);
    -webkit-animation: fall-151 130s -10s linear infinite;
    animation: fall-151 130s -10s linear infinite;
  }
  @keyframes fall-151 {
    37.272% {
      -webkit-transform: translate(77.8943vw, 447.264px) scale(0.4708);
      transform: translate(77.8943vw, 447.264px) scale(0.4708);
    }
    to {
      -webkit-transform: translate(76.6867vw, 100vh) scale(0.4708);
      transform: translate(76.6867vw, 100vh) scale(0.4708);
    }
  }
  .snow-1:nth-child(152) {
    opacity: 0.3386;
    -webkit-transform: translate(0.6007vw, -10px) scale(0.5678);
    transform: translate(0.6007vw, -10px) scale(0.5678);
    -webkit-animation: fall-152 115s -7s linear infinite;
    animation: fall-152 115s -7s linear infinite;
  }
  @keyframes fall-152 {
    75.751% {
      -webkit-transform: translate(-2.4638vw, 909.012px) scale(0.5678);
      transform: translate(-2.4638vw, 909.012px) scale(0.5678);
    }
    to {
      -webkit-transform: translate(-0.93155vw, 100vh) scale(0.5678);
      transform: translate(-0.93155vw, 100vh) scale(0.5678);
    }
  }
  .snow-1:nth-child(153) {
    opacity: 0.4323;
    -webkit-transform: translate(35.8767vw, -10px) scale(0.6438);
    transform: translate(35.8767vw, -10px) scale(0.6438);
    -webkit-animation: fall-153 85s -20s linear infinite;
    animation: fall-153 85s -20s linear infinite;
  }
  @keyframes fall-153 {
    77.156% {
      -webkit-transform: translate(41.8707vw, 925.872px) scale(0.6438);
      transform: translate(41.8707vw, 925.872px) scale(0.6438);
    }
    to {
      -webkit-transform: translate(38.8737vw, 100vh) scale(0.6438);
      transform: translate(38.8737vw, 100vh) scale(0.6438);
    }
  }
  .snow-1:nth-child(154) {
    opacity: 0.4738;
    -webkit-transform: translate(26.2973vw, -10px) scale(0.2449);
    transform: translate(26.2973vw, -10px) scale(0.2449);
    -webkit-animation: fall-154 130s -14s linear infinite;
    animation: fall-154 130s -14s linear infinite;
  }
  @keyframes fall-154 {
    50.643% {
      -webkit-transform: translate(20.1302vw, 607.716px) scale(0.2449);
      transform: translate(20.1302vw, 607.716px) scale(0.2449);
    }
    to {
      -webkit-transform: translate(23.21375vw, 100vh) scale(0.2449);
      transform: translate(23.21375vw, 100vh) scale(0.2449);
    }
  }
  .snow-1:nth-child(155) {
    opacity: 0.1949;
    -webkit-transform: translate(87.0481vw, -10px) scale(0.2903);
    transform: translate(87.0481vw, -10px) scale(0.2903);
    -webkit-animation: fall-155 100s -17s linear infinite;
    animation: fall-155 100s -17s linear infinite;
  }
  @keyframes fall-155 {
    52.34% {
      -webkit-transform: translate(77.5357vw, 628.08px) scale(0.2903);
      transform: translate(77.5357vw, 628.08px) scale(0.2903);
    }
    to {
      -webkit-transform: translate(82.2919vw, 100vh) scale(0.2903);
      transform: translate(82.2919vw, 100vh) scale(0.2903);
    }
  }
  .snow-1:nth-child(156) {
    opacity: 0.0721;
    -webkit-transform: translate(67.7988vw, -10px) scale(0.3456);
    transform: translate(67.7988vw, -10px) scale(0.3456);
    -webkit-animation: fall-156 100s -20s linear infinite;
    animation: fall-156 100s -20s linear infinite;
  }
  @keyframes fall-156 {
    75.462% {
      -webkit-transform: translate(70.1298vw, 905.544px) scale(0.3456);
      transform: translate(70.1298vw, 905.544px) scale(0.3456);
    }
    to {
      -webkit-transform: translate(68.9643vw, 100vh) scale(0.3456);
      transform: translate(68.9643vw, 100vh) scale(0.3456);
    }
  }
  .snow-1:nth-child(157) {
    opacity: 0.9753;
    -webkit-transform: translate(64.0534vw, -10px) scale(0.2161);
    transform: translate(64.0534vw, -10px) scale(0.2161);
    -webkit-animation: fall-157 145s -9s linear infinite;
    animation: fall-157 145s -9s linear infinite;
  }
  @keyframes fall-157 {
    65.058% {
      -webkit-transform: translate(71.4041vw, 780.696px) scale(0.2161);
      transform: translate(71.4041vw, 780.696px) scale(0.2161);
    }
    to {
      -webkit-transform: translate(67.72875vw, 100vh) scale(0.2161);
      transform: translate(67.72875vw, 100vh) scale(0.2161);
    }
  }
  .snow-1:nth-child(158) {
    opacity: 0.084;
    -webkit-transform: translate(37.1792vw, -10px) scale(0.8486);
    transform: translate(37.1792vw, -10px) scale(0.8486);
    -webkit-animation: fall-158 65s -21s linear infinite;
    animation: fall-158 65s -21s linear infinite;
  }
  @keyframes fall-158 {
    47.951% {
      -webkit-transform: translate(38.3183vw, 575.412px) scale(0.8486);
      transform: translate(38.3183vw, 575.412px) scale(0.8486);
    }
    to {
      -webkit-transform: translate(37.74875vw, 100vh) scale(0.8486);
      transform: translate(37.74875vw, 100vh) scale(0.8486);
    }
  }
  .snow-1:nth-child(159) {
    opacity: 0.5871;
    -webkit-transform: translate(32.3278vw, -10px) scale(0.822);
    transform: translate(32.3278vw, -10px) scale(0.822);
    -webkit-animation: fall-159 90s -19s linear infinite;
    animation: fall-159 90s -19s linear infinite;
  }
  @keyframes fall-159 {
    46.456% {
      -webkit-transform: translate(27.499vw, 557.472px) scale(0.822);
      transform: translate(27.499vw, 557.472px) scale(0.822);
    }
    to {
      -webkit-transform: translate(29.9134vw, 100vh) scale(0.822);
      transform: translate(29.9134vw, 100vh) scale(0.822);
    }
  }
  .snow-1:nth-child(160) {
    opacity: 0.8854;
    -webkit-transform: translate(76.2169vw, -10px) scale(0.7098);
    transform: translate(76.2169vw, -10px) scale(0.7098);
    -webkit-animation: fall-160 70s -26s linear infinite;
    animation: fall-160 70s -26s linear infinite;
  }
  @keyframes fall-160 {
    35.66% {
      -webkit-transform: translate(77.5842vw, 427.92px) scale(0.7098);
      transform: translate(77.5842vw, 427.92px) scale(0.7098);
    }
    to {
      -webkit-transform: translate(76.90055vw, 100vh) scale(0.7098);
      transform: translate(76.90055vw, 100vh) scale(0.7098);
    }
  }
  .snow-1:nth-child(161) {
    opacity: 0.824;
    -webkit-transform: translate(56.045vw, -10px) scale(0.7861);
    transform: translate(56.045vw, -10px) scale(0.7861);
    -webkit-animation: fall-161 115s -18s linear infinite;
    animation: fall-161 115s -18s linear infinite;
  }
  @keyframes fall-161 {
    68.972% {
      -webkit-transform: translate(60.0784vw, 827.664px) scale(0.7861);
      transform: translate(60.0784vw, 827.664px) scale(0.7861);
    }
    to {
      -webkit-transform: translate(58.0617vw, 100vh) scale(0.7861);
      transform: translate(58.0617vw, 100vh) scale(0.7861);
    }
  }
  .snow-1:nth-child(162) {
    opacity: 0.4746;
    -webkit-transform: translate(49.5632vw, -10px) scale(0.644);
    transform: translate(49.5632vw, -10px) scale(0.644);
    -webkit-animation: fall-162 75s -24s linear infinite;
    animation: fall-162 75s -24s linear infinite;
  }
  @keyframes fall-162 {
    65.528% {
      -webkit-transform: translate(53.9719vw, 786.336px) scale(0.644);
      transform: translate(53.9719vw, 786.336px) scale(0.644);
    }
    to {
      -webkit-transform: translate(51.76755vw, 100vh) scale(0.644);
      transform: translate(51.76755vw, 100vh) scale(0.644);
    }
  }
  .snow-1:nth-child(163) {
    opacity: 0.4014;
    -webkit-transform: translate(82.906vw, -10px) scale(0.2528);
    transform: translate(82.906vw, -10px) scale(0.2528);
    -webkit-animation: fall-163 100s -25s linear infinite;
    animation: fall-163 100s -25s linear infinite;
  }
  @keyframes fall-163 {
    59.874% {
      -webkit-transform: translate(84.2927vw, 718.488px) scale(0.2528);
      transform: translate(84.2927vw, 718.488px) scale(0.2528);
    }
    to {
      -webkit-transform: translate(83.59935vw, 100vh) scale(0.2528);
      transform: translate(83.59935vw, 100vh) scale(0.2528);
    }
  }
  .snow-1:nth-child(164) {
    opacity: 0.4975;
    -webkit-transform: translate(46.2796vw, -10px) scale(0.015);
    transform: translate(46.2796vw, -10px) scale(0.015);
    -webkit-animation: fall-164 110s -28s linear infinite;
    animation: fall-164 110s -28s linear infinite;
  }
  @keyframes fall-164 {
    48.991% {
      -webkit-transform: translate(47.6283vw, 587.892px) scale(0.015);
      transform: translate(47.6283vw, 587.892px) scale(0.015);
    }
    to {
      -webkit-transform: translate(46.95395vw, 100vh) scale(0.015);
      transform: translate(46.95395vw, 100vh) scale(0.015);
    }
  }
  .snow-1:nth-child(165) {
    opacity: 0.514;
    -webkit-transform: translate(75.5577vw, -10px) scale(0.8896);
    transform: translate(75.5577vw, -10px) scale(0.8896);
    -webkit-animation: fall-165 120s -30s linear infinite;
    animation: fall-165 120s -30s linear infinite;
  }
  @keyframes fall-165 {
    61.097% {
      -webkit-transform: translate(66.5643vw, 733.164px) scale(0.8896);
      transform: translate(66.5643vw, 733.164px) scale(0.8896);
    }
    to {
      -webkit-transform: translate(71.061vw, 100vh) scale(0.8896);
      transform: translate(71.061vw, 100vh) scale(0.8896);
    }
  }
  .snow-1:nth-child(166) {
    opacity: 0.9531;
    -webkit-transform: translate(77.0292vw, -10px) scale(0.6027);
    transform: translate(77.0292vw, -10px) scale(0.6027);
    -webkit-animation: fall-166 130s -5s linear infinite;
    animation: fall-166 130s -5s linear infinite;
  }
  @keyframes fall-166 {
    45.997% {
      -webkit-transform: translate(79.2617vw, 551.964px) scale(0.6027);
      transform: translate(79.2617vw, 551.964px) scale(0.6027);
    }
    to {
      -webkit-transform: translate(78.14545vw, 100vh) scale(0.6027);
      transform: translate(78.14545vw, 100vh) scale(0.6027);
    }
  }
  .snow-1:nth-child(167) {
    opacity: 0.8834;
    -webkit-transform: translate(59.6509vw, -10px) scale(0.0228);
    transform: translate(59.6509vw, -10px) scale(0.0228);
    -webkit-animation: fall-167 100s -17s linear infinite;
    animation: fall-167 100s -17s linear infinite;
  }
  @keyframes fall-167 {
    42.621% {
      -webkit-transform: translate(57.6472vw, 511.452px) scale(0.0228);
      transform: translate(57.6472vw, 511.452px) scale(0.0228);
    }
    to {
      -webkit-transform: translate(58.64905vw, 100vh) scale(0.0228);
      transform: translate(58.64905vw, 100vh) scale(0.0228);
    }
  }
  .snow-1:nth-child(168) {
    opacity: 0.0992;
    -webkit-transform: translate(43.3216vw, -10px) scale(0.1818);
    transform: translate(43.3216vw, -10px) scale(0.1818);
    -webkit-animation: fall-168 145s -28s linear infinite;
    animation: fall-168 145s -28s linear infinite;
  }
  @keyframes fall-168 {
    63.587% {
      -webkit-transform: translate(44.8462vw, 763.044px) scale(0.1818);
      transform: translate(44.8462vw, 763.044px) scale(0.1818);
    }
    to {
      -webkit-transform: translate(44.0839vw, 100vh) scale(0.1818);
      transform: translate(44.0839vw, 100vh) scale(0.1818);
    }
  }
  .snow-1:nth-child(169) {
    opacity: 0.3815;
    -webkit-transform: translate(70.4036vw, -10px) scale(0.5969);
    transform: translate(70.4036vw, -10px) scale(0.5969);
    -webkit-animation: fall-169 120s -14s linear infinite;
    animation: fall-169 120s -14s linear infinite;
  }
  @keyframes fall-169 {
    56.212% {
      -webkit-transform: translate(73.5433vw, 674.544px) scale(0.5969);
      transform: translate(73.5433vw, 674.544px) scale(0.5969);
    }
    to {
      -webkit-transform: translate(71.97345vw, 100vh) scale(0.5969);
      transform: translate(71.97345vw, 100vh) scale(0.5969);
    }
  }
  .snow-1:nth-child(170) {
    opacity: 0.4607;
    -webkit-transform: translate(21.2456vw, -10px) scale(0.4357);
    transform: translate(21.2456vw, -10px) scale(0.4357);
    -webkit-animation: fall-170 95s -22s linear infinite;
    animation: fall-170 95s -22s linear infinite;
  }
  @keyframes fall-170 {
    71.015% {
      -webkit-transform: translate(28.859vw, 852.18px) scale(0.4357);
      transform: translate(28.859vw, 852.18px) scale(0.4357);
    }
    to {
      -webkit-transform: translate(25.0523vw, 100vh) scale(0.4357);
      transform: translate(25.0523vw, 100vh) scale(0.4357);
    }
  }
  .snow-1:nth-child(171) {
    opacity: 0.943;
    -webkit-transform: translate(71.167vw, -10px) scale(0.1126);
    transform: translate(71.167vw, -10px) scale(0.1126);
    -webkit-animation: fall-171 135s -29s linear infinite;
    animation: fall-171 135s -29s linear infinite;
  }
  @keyframes fall-171 {
    49.742% {
      -webkit-transform: translate(62.6175vw, 596.904px) scale(0.1126);
      transform: translate(62.6175vw, 596.904px) scale(0.1126);
    }
    to {
      -webkit-transform: translate(66.89225vw, 100vh) scale(0.1126);
      transform: translate(66.89225vw, 100vh) scale(0.1126);
    }
  }
  .snow-1:nth-child(172) {
    opacity: 0.0549;
    -webkit-transform: translate(33.9787vw, -10px) scale(0.4501);
    transform: translate(33.9787vw, -10px) scale(0.4501);
    -webkit-animation: fall-172 70s -2s linear infinite;
    animation: fall-172 70s -2s linear infinite;
  }
  @keyframes fall-172 {
    78.969% {
      -webkit-transform: translate(39.9585vw, 947.628px) scale(0.4501);
      transform: translate(39.9585vw, 947.628px) scale(0.4501);
    }
    to {
      -webkit-transform: translate(36.9686vw, 100vh) scale(0.4501);
      transform: translate(36.9686vw, 100vh) scale(0.4501);
    }
  }
  .snow-1:nth-child(173) {
    opacity: 0.2673;
    -webkit-transform: translate(12.4336vw, -10px) scale(0.5733);
    transform: translate(12.4336vw, -10px) scale(0.5733);
    -webkit-animation: fall-173 120s -3s linear infinite;
    animation: fall-173 120s -3s linear infinite;
  }
  @keyframes fall-173 {
    31.943% {
      -webkit-transform: translate(16.6232vw, 383.316px) scale(0.5733);
      transform: translate(16.6232vw, 383.316px) scale(0.5733);
    }
    to {
      -webkit-transform: translate(14.5284vw, 100vh) scale(0.5733);
      transform: translate(14.5284vw, 100vh) scale(0.5733);
    }
  }
  .snow-1:nth-child(174) {
    opacity: 0.1641;
    -webkit-transform: translate(17.8512vw, -10px) scale(0.1992);
    transform: translate(17.8512vw, -10px) scale(0.1992);
    -webkit-animation: fall-174 70s -4s linear infinite;
    animation: fall-174 70s -4s linear infinite;
  }
  @keyframes fall-174 {
    33.797% {
      -webkit-transform: translate(12.8263vw, 405.564px) scale(0.1992);
      transform: translate(12.8263vw, 405.564px) scale(0.1992);
    }
    to {
      -webkit-transform: translate(15.33875vw, 100vh) scale(0.1992);
      transform: translate(15.33875vw, 100vh) scale(0.1992);
    }
  }
  .snow-1:nth-child(175) {
    opacity: 0.9269;
    -webkit-transform: translate(86.651vw, -10px) scale(0.2166);
    transform: translate(86.651vw, -10px) scale(0.2166);
    -webkit-animation: fall-175 55s -19s linear infinite;
    animation: fall-175 55s -19s linear infinite;
  }
  @keyframes fall-175 {
    78.885% {
      -webkit-transform: translate(93.7691vw, 946.62px) scale(0.2166);
      transform: translate(93.7691vw, 946.62px) scale(0.2166);
    }
    to {
      -webkit-transform: translate(90.21005vw, 100vh) scale(0.2166);
      transform: translate(90.21005vw, 100vh) scale(0.2166);
    }
  }
  .snow-1:nth-child(176) {
    opacity: 0.4994;
    -webkit-transform: translate(43.9893vw, -10px) scale(0.9248);
    transform: translate(43.9893vw, -10px) scale(0.9248);
    -webkit-animation: fall-176 90s -18s linear infinite;
    animation: fall-176 90s -18s linear infinite;
  }
  @keyframes fall-176 {
    78.469% {
      -webkit-transform: translate(38.0018vw, 941.628px) scale(0.9248);
      transform: translate(38.0018vw, 941.628px) scale(0.9248);
    }
    to {
      -webkit-transform: translate(40.99555vw, 100vh) scale(0.9248);
      transform: translate(40.99555vw, 100vh) scale(0.9248);
    }
  }
  .snow-1:nth-child(177) {
    opacity: 0.5053;
    -webkit-transform: translate(87.9039vw, -10px) scale(0.5209);
    transform: translate(87.9039vw, -10px) scale(0.5209);
    -webkit-animation: fall-177 50s -4s linear infinite;
    animation: fall-177 50s -4s linear infinite;
  }
  @keyframes fall-177 {
    31.713% {
      -webkit-transform: translate(96.3896vw, 380.556px) scale(0.5209);
      transform: translate(96.3896vw, 380.556px) scale(0.5209);
    }
    to {
      -webkit-transform: translate(92.14675vw, 100vh) scale(0.5209);
      transform: translate(92.14675vw, 100vh) scale(0.5209);
    }
  }
  .snow-1:nth-child(178) {
    opacity: 0.1127;
    -webkit-transform: translate(74.8692vw, -10px) scale(0.7826);
    transform: translate(74.8692vw, -10px) scale(0.7826);
    -webkit-animation: fall-178 65s -16s linear infinite;
    animation: fall-178 65s -16s linear infinite;
  }
  @keyframes fall-178 {
    72.558% {
      -webkit-transform: translate(82.7274vw, 870.696px) scale(0.7826);
      transform: translate(82.7274vw, 870.696px) scale(0.7826);
    }
    to {
      -webkit-transform: translate(78.7983vw, 100vh) scale(0.7826);
      transform: translate(78.7983vw, 100vh) scale(0.7826);
    }
  }
  .snow-1:nth-child(179) {
    opacity: 0.6592;
    -webkit-transform: translate(71.6553vw, -10px) scale(0.1214);
    transform: translate(71.6553vw, -10px) scale(0.1214);
    -webkit-animation: fall-179 80s -13s linear infinite;
    animation: fall-179 80s -13s linear infinite;
  }
  @keyframes fall-179 {
    36.534% {
      -webkit-transform: translate(70.7897vw, 438.408px) scale(0.1214);
      transform: translate(70.7897vw, 438.408px) scale(0.1214);
    }
    to {
      -webkit-transform: translate(71.2225vw, 100vh) scale(0.1214);
      transform: translate(71.2225vw, 100vh) scale(0.1214);
    }
  }
  .snow-1:nth-child(180) {
    opacity: 0.4933;
    -webkit-transform: translate(75.6065vw, -10px) scale(0.0927);
    transform: translate(75.6065vw, -10px) scale(0.0927);
    -webkit-animation: fall-180 140s -20s linear infinite;
    animation: fall-180 140s -20s linear infinite;
  }
  @keyframes fall-180 {
    31.293% {
      -webkit-transform: translate(81.1791vw, 375.516px) scale(0.0927);
      transform: translate(81.1791vw, 375.516px) scale(0.0927);
    }
    to {
      -webkit-transform: translate(78.3928vw, 100vh) scale(0.0927);
      transform: translate(78.3928vw, 100vh) scale(0.0927);
    }
  }
  .snow-1:nth-child(181) {
    opacity: 0.1353;
    -webkit-transform: translate(36.9913vw, -10px) scale(0.6206);
    transform: translate(36.9913vw, -10px) scale(0.6206);
    -webkit-animation: fall-181 115s -3s linear infinite;
    animation: fall-181 115s -3s linear infinite;
  }
  @keyframes fall-181 {
    66.925% {
      -webkit-transform: translate(31.6196vw, 803.1px) scale(0.6206);
      transform: translate(31.6196vw, 803.1px) scale(0.6206);
    }
    to {
      -webkit-transform: translate(34.30545vw, 100vh) scale(0.6206);
      transform: translate(34.30545vw, 100vh) scale(0.6206);
    }
  }
  .snow-1:nth-child(182) {
    opacity: 0.8559;
    -webkit-transform: translate(16.0044vw, -10px) scale(0.2305);
    transform: translate(16.0044vw, -10px) scale(0.2305);
    -webkit-animation: fall-182 65s -20s linear infinite;
    animation: fall-182 65s -20s linear infinite;
  }
  @keyframes fall-182 {
    60.625% {
      -webkit-transform: translate(20.0709vw, 727.5px) scale(0.2305);
      transform: translate(20.0709vw, 727.5px) scale(0.2305);
    }
    to {
      -webkit-transform: translate(18.03765vw, 100vh) scale(0.2305);
      transform: translate(18.03765vw, 100vh) scale(0.2305);
    }
  }
  .snow-1:nth-child(183) {
    opacity: 0.3496;
    -webkit-transform: translate(8.8704vw, -10px) scale(0.8873);
    transform: translate(8.8704vw, -10px) scale(0.8873);
    -webkit-animation: fall-183 150s -14s linear infinite;
    animation: fall-183 150s -14s linear infinite;
  }
  @keyframes fall-183 {
    35.174% {
      -webkit-transform: translate(3.1857vw, 422.088px) scale(0.8873);
      transform: translate(3.1857vw, 422.088px) scale(0.8873);
    }
    to {
      -webkit-transform: translate(6.02805vw, 100vh) scale(0.8873);
      transform: translate(6.02805vw, 100vh) scale(0.8873);
    }
  }
  .snow-1:nth-child(184) {
    opacity: 0.3955;
    -webkit-transform: translate(11.9644vw, -10px) scale(0.2547);
    transform: translate(11.9644vw, -10px) scale(0.2547);
    -webkit-animation: fall-184 90s -12s linear infinite;
    animation: fall-184 90s -12s linear infinite;
  }
  @keyframes fall-184 {
    40.362% {
      -webkit-transform: translate(14.8498vw, 484.344px) scale(0.2547);
      transform: translate(14.8498vw, 484.344px) scale(0.2547);
    }
    to {
      -webkit-transform: translate(13.4071vw, 100vh) scale(0.2547);
      transform: translate(13.4071vw, 100vh) scale(0.2547);
    }
  }
  .snow-1:nth-child(185) {
    opacity: 0.9224;
    -webkit-transform: translate(32.9469vw, -10px) scale(0.4668);
    transform: translate(32.9469vw, -10px) scale(0.4668);
    -webkit-animation: fall-185 110s -14s linear infinite;
    animation: fall-185 110s -14s linear infinite;
  }
  @keyframes fall-185 {
    43.182% {
      -webkit-transform: translate(24.6557vw, 518.184px) scale(0.4668);
      transform: translate(24.6557vw, 518.184px) scale(0.4668);
    }
    to {
      -webkit-transform: translate(28.8013vw, 100vh) scale(0.4668);
      transform: translate(28.8013vw, 100vh) scale(0.4668);
    }
  }
  .snow-1:nth-child(186) {
    opacity: 0.5722;
    -webkit-transform: translate(60.5862vw, -10px) scale(0.3238);
    transform: translate(60.5862vw, -10px) scale(0.3238);
    -webkit-animation: fall-186 140s -7s linear infinite;
    animation: fall-186 140s -7s linear infinite;
  }
  @keyframes fall-186 {
    59.715% {
      -webkit-transform: translate(62.6333vw, 716.58px) scale(0.3238);
      transform: translate(62.6333vw, 716.58px) scale(0.3238);
    }
    to {
      -webkit-transform: translate(61.60975vw, 100vh) scale(0.3238);
      transform: translate(61.60975vw, 100vh) scale(0.3238);
    }
  }
  .snow-1:nth-child(187) {
    opacity: 0.4428;
    -webkit-transform: translate(23.1343vw, -10px) scale(0.9115);
    transform: translate(23.1343vw, -10px) scale(0.9115);
    -webkit-animation: fall-187 100s -26s linear infinite;
    animation: fall-187 100s -26s linear infinite;
  }
  @keyframes fall-187 {
    38.948% {
      -webkit-transform: translate(19.4043vw, 467.376px) scale(0.9115);
      transform: translate(19.4043vw, 467.376px) scale(0.9115);
    }
    to {
      -webkit-transform: translate(21.2693vw, 100vh) scale(0.9115);
      transform: translate(21.2693vw, 100vh) scale(0.9115);
    }
  }
  .snow-1:nth-child(188) {
    opacity: 0.6201;
    -webkit-transform: translate(58.3965vw, -10px) scale(0.9085);
    transform: translate(58.3965vw, -10px) scale(0.9085);
    -webkit-animation: fall-188 95s -26s linear infinite;
    animation: fall-188 95s -26s linear infinite;
  }
  @keyframes fall-188 {
    75.923% {
      -webkit-transform: translate(51.2983vw, 911.076px) scale(0.9085);
      transform: translate(51.2983vw, 911.076px) scale(0.9085);
    }
    to {
      -webkit-transform: translate(54.8474vw, 100vh) scale(0.9085);
      transform: translate(54.8474vw, 100vh) scale(0.9085);
    }
  }
  .snow-1:nth-child(189) {
    opacity: 0.3511;
    -webkit-transform: translate(70.4109vw, -10px) scale(0.1976);
    transform: translate(70.4109vw, -10px) scale(0.1976);
    -webkit-animation: fall-189 120s -29s linear infinite;
    animation: fall-189 120s -29s linear infinite;
  }
  @keyframes fall-189 {
    76.396% {
      -webkit-transform: translate(76.3924vw, 916.752px) scale(0.1976);
      transform: translate(76.3924vw, 916.752px) scale(0.1976);
    }
    to {
      -webkit-transform: translate(73.40165vw, 100vh) scale(0.1976);
      transform: translate(73.40165vw, 100vh) scale(0.1976);
    }
  }
  .snow-1:nth-child(190) {
    opacity: 0.3144;
    -webkit-transform: translate(24.4334vw, -10px) scale(0.8557);
    transform: translate(24.4334vw, -10px) scale(0.8557);
    -webkit-animation: fall-190 125s -9s linear infinite;
    animation: fall-190 125s -9s linear infinite;
  }
  @keyframes fall-190 {
    30.53% {
      -webkit-transform: translate(19.9532vw, 366.36px) scale(0.8557);
      transform: translate(19.9532vw, 366.36px) scale(0.8557);
    }
    to {
      -webkit-transform: translate(22.1933vw, 100vh) scale(0.8557);
      transform: translate(22.1933vw, 100vh) scale(0.8557);
    }
  }
  .snow-1:nth-child(191) {
    opacity: 0.2512;
    -webkit-transform: translate(74.5034vw, -10px) scale(0.346);
    transform: translate(74.5034vw, -10px) scale(0.346);
    -webkit-animation: fall-191 105s -20s linear infinite;
    animation: fall-191 105s -20s linear infinite;
  }
  @keyframes fall-191 {
    69.114% {
      -webkit-transform: translate(69.0716vw, 829.368px) scale(0.346);
      transform: translate(69.0716vw, 829.368px) scale(0.346);
    }
    to {
      -webkit-transform: translate(71.7875vw, 100vh) scale(0.346);
      transform: translate(71.7875vw, 100vh) scale(0.346);
    }
  }
  .snow-1:nth-child(192) {
    opacity: 0.1138;
    -webkit-transform: translate(57.8904vw, -10px) scale(0.4244);
    transform: translate(57.8904vw, -10px) scale(0.4244);
    -webkit-animation: fall-192 130s -2s linear infinite;
    animation: fall-192 130s -2s linear infinite;
  }
  @keyframes fall-192 {
    38.115% {
      -webkit-transform: translate(52.9889vw, 457.38px) scale(0.4244);
      transform: translate(52.9889vw, 457.38px) scale(0.4244);
    }
    to {
      -webkit-transform: translate(55.43965vw, 100vh) scale(0.4244);
      transform: translate(55.43965vw, 100vh) scale(0.4244);
    }
  }
  .snow-1:nth-child(193) {
    opacity: 0.754;
    -webkit-transform: translate(5.9366vw, -10px) scale(0.4139);
    transform: translate(5.9366vw, -10px) scale(0.4139);
    -webkit-animation: fall-193 105s -15s linear infinite;
    animation: fall-193 105s -15s linear infinite;
  }
  @keyframes fall-193 {
    72.655% {
      -webkit-transform: translate(5.8361vw, 871.86px) scale(0.4139);
      transform: translate(5.8361vw, 871.86px) scale(0.4139);
    }
    to {
      -webkit-transform: translate(5.88635vw, 100vh) scale(0.4139);
      transform: translate(5.88635vw, 100vh) scale(0.4139);
    }
  }
  .snow-1:nth-child(194) {
    opacity: 0.976;
    -webkit-transform: translate(86.7709vw, -10px) scale(0.5927);
    transform: translate(86.7709vw, -10px) scale(0.5927);
    -webkit-animation: fall-194 85s -12s linear infinite;
    animation: fall-194 85s -12s linear infinite;
  }
  @keyframes fall-194 {
    78.382% {
      -webkit-transform: translate(96.3021vw, 940.584px) scale(0.5927);
      transform: translate(96.3021vw, 940.584px) scale(0.5927);
    }
    to {
      -webkit-transform: translate(91.5365vw, 100vh) scale(0.5927);
      transform: translate(91.5365vw, 100vh) scale(0.5927);
    }
  }
  .snow-1:nth-child(195) {
    opacity: 0.4187;
    -webkit-transform: translate(75.6024vw, -10px) scale(0.7017);
    transform: translate(75.6024vw, -10px) scale(0.7017);
    -webkit-animation: fall-195 90s -6s linear infinite;
    animation: fall-195 90s -6s linear infinite;
  }
  @keyframes fall-195 {
    49.112% {
      -webkit-transform: translate(77.789vw, 589.344px) scale(0.7017);
      transform: translate(77.789vw, 589.344px) scale(0.7017);
    }
    to {
      -webkit-transform: translate(76.6957vw, 100vh) scale(0.7017);
      transform: translate(76.6957vw, 100vh) scale(0.7017);
    }
  }
  .snow-1:nth-child(196) {
    opacity: 0.1711;
    -webkit-transform: translate(22.9582vw, -10px) scale(0.6347);
    transform: translate(22.9582vw, -10px) scale(0.6347);
    -webkit-animation: fall-196 125s -27s linear infinite;
    animation: fall-196 125s -27s linear infinite;
  }
  @keyframes fall-196 {
    78.063% {
      -webkit-transform: translate(32.2929vw, 936.756px) scale(0.6347);
      transform: translate(32.2929vw, 936.756px) scale(0.6347);
    }
    to {
      -webkit-transform: translate(27.62555vw, 100vh) scale(0.6347);
      transform: translate(27.62555vw, 100vh) scale(0.6347);
    }
  }
  .snow-1:nth-child(197) {
    opacity: 0.6489;
    -webkit-transform: translate(87.1984vw, -10px) scale(0.8876);
    transform: translate(87.1984vw, -10px) scale(0.8876);
    -webkit-animation: fall-197 95s -17s linear infinite;
    animation: fall-197 95s -17s linear infinite;
  }
  @keyframes fall-197 {
    56.934% {
      -webkit-transform: translate(91.9373vw, 683.208px) scale(0.8876);
      transform: translate(91.9373vw, 683.208px) scale(0.8876);
    }
    to {
      -webkit-transform: translate(89.56785vw, 100vh) scale(0.8876);
      transform: translate(89.56785vw, 100vh) scale(0.8876);
    }
  }
  .snow-1:nth-child(198) {
    opacity: 0.844;
    -webkit-transform: translate(14.0409vw, -10px) scale(0.2518);
    transform: translate(14.0409vw, -10px) scale(0.2518);
    -webkit-animation: fall-198 115s -20s linear infinite;
    animation: fall-198 115s -20s linear infinite;
  }
  @keyframes fall-198 {
    62.036% {
      -webkit-transform: translate(5.8873vw, 744.432px) scale(0.2518);
      transform: translate(5.8873vw, 744.432px) scale(0.2518);
    }
    to {
      -webkit-transform: translate(9.9641vw, 100vh) scale(0.2518);
      transform: translate(9.9641vw, 100vh) scale(0.2518);
    }
  }
  .snow-1:nth-child(199) {
    opacity: 0.5176;
    -webkit-transform: translate(43.2084vw, -10px) scale(0.4254);
    transform: translate(43.2084vw, -10px) scale(0.4254);
    -webkit-animation: fall-199 80s -24s linear infinite;
    animation: fall-199 80s -24s linear infinite;
  }
  @keyframes fall-199 {
    61.356% {
      -webkit-transform: translate(42.1493vw, 736.272px) scale(0.4254);
      transform: translate(42.1493vw, 736.272px) scale(0.4254);
    }
    to {
      -webkit-transform: translate(42.67885vw, 100vh) scale(0.4254);
      transform: translate(42.67885vw, 100vh) scale(0.4254);
    }
  }
  .snow-1:nth-child(200) {
    opacity: 0.7708;
    -webkit-transform: translate(19.5566vw, -10px) scale(0.9188);
    transform: translate(19.5566vw, -10px) scale(0.9188);
    -webkit-animation: fall-200 75s -11s linear infinite;
    animation: fall-200 75s -11s linear infinite;
  }
  @keyframes fall-200 {
    69.127% {
      -webkit-transform: translate(16.0944vw, 829.524px) scale(0.9188);
      transform: translate(16.0944vw, 829.524px) scale(0.9188);
    }
    to {
      -webkit-transform: translate(17.8255vw, 100vh) scale(0.9188);
      transform: translate(17.8255vw, 100vh) scale(0.9188);
    }
  }