/* progress bar  */
.progress-line {
  height: 10px;
  width: 100%;
  background: #f0f0f0;
  position: relative;
  transform-origin: left;
  border-radius: 10px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%), 0 1px rgb(255 255 255 / 80%);
  animation: animate 1s cubic-bezier(1, 0, 0.5, 1) forwards;
}
.progress-line span.animated {
  animation: animate 1s cubic-bezier(1, 0, 0.5, 1) forwards;
}
.progress-line span {
  height: 100%;
  position: absolute;
  border-radius: 10px;
  transform: scaleX(0);
  transform-origin: left;
  background: black;
}
.progress-line.html span {
  width: 90%;
}
.animated::before {
  position: absolute;
  content: "";
  top: -10px;
  right: 0;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-top-color: #E8AF94;
  opacity: 0;
  animation: showText2 0.5s 1.5s linear forwards;
}
.animated::after {
  /* position: absolute; */

  /* top: -28px; */
  /* right: 0; */

  font-weight: 500;
  background: #E8AF94;
  color: black;
  padding: 1px 8px;
  font-size: 12px;
  border-radius: 3px;
  opacity: 0;
  animation: showText2 0.5s 1.5s linear forwards;
}
@keyframes showText2 {
  from {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}
