.moving-text .Typewriter__cursor {
  color: #f00512;
  margin-left: 5px;
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.Typewriter {
  display: inline;
  margin-left: 10px;
}
